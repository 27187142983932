<template>
  <div class="main35 flex-col">
        <div class="box16 flex-col">
          <span class="word81">安青前程的slogan</span>
          <div class="bd17 flex-row">
            <div class="layer31 flex-col" @click="$router.push('product')">
              <div class="bd18 flex-row">
                <img
                  class="label3"
                  referrerpolicy="no-referrer"
                  :src="require('@/assets/basic/nav_download_active.png')"
                />
                <span class="txt49">立即下载</span>
              </div>
            </div>
            <div class="layer31 flex-col" @click="$router.push('product')">
              <div class="bd18 flex-row">
                <img
                  class="label3"
                  referrerpolicy="no-referrer"
                  :src="require('@/assets/basic/nav_company.png')"
                />
                <span class="txt49">企业入驻</span>
              </div>
            </div>
            <div class="layer31 flex-col" @click="$router.push('product')">
              <div class="bd18 flex-row">
                <img
                  class="label3"
                  referrerpolicy="no-referrer"
                  :src="require('@/assets/basic/nav_school.png')"
                />
                <span class="txt49">院校入驻</span>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
export default {
    name:'logo'
}
</script>

<style lang="scss" scoped>
.main35 {
      height: 200px;
      background: linear-gradient(179deg, #AC0202 0%, #C40202 56%, #AC0202 100%);
      width: 100%;margin:0 auto;
      justify-content: center;
      align-items: center;
      .box16 {
        width: 464px;
        height: 100px;
        .word81 {
          width: 245px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 30px;
          font-family: PingFangSC-Semibold;
          white-space: nowrap;
          line-height: 30px;
          text-align: center;
          align-self: center;
        }
        .bd17 {
          width: 464px;
          height: 44px;
          margin-top: 26px;
          justify-content: space-between;
          .layer31 {
            height: 44px;
            border-radius: 6px;
            background-color: rgba(255, 255, 255, 1);
            width: 128px;
            justify-content: center;
            align-items: center;
            transition: .5s all ease;
            .bd18 {
              width: 88px;
              height: 16px;
              justify-content: space-between;
              .label3 {
                width: 16px;
                height: 16px;
              }
              .txt49 {
                width: 64px;
                display: block;
                overflow-wrap: break-word;
                color: rgba(189, 39, 27, 1);
                font-size: 16px;
                white-space: nowrap;
                line-height: 16px;
                text-align: center;
              }
            }
            &:hover {
                transform: translateY(-2px);
                box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 10%);
            }
          }
          
         
        }
      }
    }
</style>