 <!--
 * @Auhtor: 文锋
 * @Date: 2021-08-07 07:37:40
 * @LastEditors: Do not edit
 * @LastEditTime: 2021-08-08 17:47:22
 * @FilePath: \anqin\src\views\SchoolLife.vue
-->
<template>
<div>
<div class="wrapper">
      <Conterheader title="企业服务" content="优质内容、活跃校园社区、便利校园应用不止陪伴你的学习，更见证你的成长" :type="3" />
      <div class="nav">
        <div class="nav-item">
          <span>菁星商城企业端</span>
          <div>
            <div class="tips">Web</div>
            <span>></span>
          </div>
        </div>
        <div class="nav-item">
          <span>菁星校园报修服务平台</span>
          <div>
            <div class="tips">Web</div>
            <span>></span>
          </div>
        </div>
        <div class="nav-item">
          <span>通信大厅后台</span>
          <div>
            <div class="tips">Web</div>
            <span>></span>
          </div>
        </div>
        <div class="nav-item">
          <span>网络大厅后台</span>
          <div>
            <div class="tips">Web</div>
            <span>></span>
          </div>
        </div>
      </div>
      <!-- 安青前程 -->
      <div class="future">
        <p>安青前程为您提供</p>
        <div class="future_cont">
          <div class="cont_nav">
            <div
              class="nav_item"
              :class="typeIndex == 0 ? 'active' : ''"
              @click="typeIndex = 0"
            >
              <i></i>
              <span>大国工匠</span>
            </div>
            <div
              class="nav_item"
              :class="typeIndex == 1 ? 'active' : ''"
              @click="typeIndex = 1"
            >
              <i></i>
              <span>第二课堂</span>
            </div>
            <div
              class="nav_item"
              :class="typeIndex == 2 ? 'active' : ''"
              @click="typeIndex = 2"
            >
              <i></i>
              <span>企业校招</span>
            </div>
            <div
              class="nav_item"
              :class="typeIndex == 3 ? 'active' : ''"
              @click="typeIndex = 3"
            >
              <i></i>
              <span>职教中心</span>
            </div>
          </div>
          <div class="cont_body">
            <div class="body_item" v-show="typeIndex == 0">
              <div class="txt">
                <span>大国工匠</span>
                <p>
                  依托行业技术优势，聚焦生态企业用人需求，立足教材和学习资源开发，协助试点院校实施证书培训，助力新一代信息技术产业人才培养。
                </p>
              </div>
              <div class="icon">
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
              </div>
            </div>
            <div class="body_item" v-show="typeIndex == 1">
              <div class="txt">
                <span>第二课堂</span>
                <p>
                  依托行业技术优势，聚焦生态企业用人需求，立足教材和学习资源开发，协助试点院校实施证书培训，助力新一代信息技术产业人才培养。
                </p>
              </div>
              <div class="icon">
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
              </div>
            </div>
            <div class="body_item" v-show="typeIndex == 2">
              <div class="txt">
                <span>企业校招</span>
                <p>
                  依托行业技术优势，聚焦生态企业用人需求，立足教材和学习资源开发，协助试点院校实施证书培训，助力新一代信息技术产业人才培养。
                </p>
              </div>
              <div class="icon">
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
              </div>
            </div>
            <div class="body_item" v-show="typeIndex == 3">
              <div class="txt">
                <span>职教中心</span>
                <p>
                  依托行业技术优势，聚焦生态企业用人需求，立足教材和学习资源开发，协助试点院校实施证书培训，助力新一代信息技术产业人才培养。
                </p>
              </div>
              <div class="icon">
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 应用场景 -->
      <div class="scene">
        <p>应用场景</p>
        <div class="scene_body">
          <div class="body_item">
            <img :src="require('@/assets/basic/company_icon01.png')" alt="" />
            <span>1+X认证</span>
            <p>
              依托行业技术优势，聚焦生态企业用人需求，立足教材和学习资源开发，协助试点院校实施证书培训，助力新一代信息技术产业人才培养。
            </p>
          </div>
          <div class="body_item">
            <img :src="require('@/assets/basic/company_icon02.png')" alt="" />
            <span>校企合作</span>
            <p>
              依托行业技术优势，聚焦生态企业用人需求，立足教材和学习资源开发，协助试点院校实施证书培训，助力新一代信息技术产业人才培养。
            </p>
          </div>
          <div class="body_item">
            <img :src="require('@/assets/basic/company_icon03.png')" alt="" />
            <span>创新创业/技能大赛</span>
            <p>
              赛项围绕新一代信息技术的发展趋势和产业中的岗位技能而设计，通过开展竞赛深化产教融合，为人工智能、移动应用开发、区块链等产业培养技能人才。
            </p>
          </div>
          <div class="body_item">
            <img :src="require('@/assets/basic/company_icon04.png')" alt="" />
            <span>技能认证</span>
            <p>
              赛项围绕新一代信息技术的发展趋势和产业中的岗位技能而设计，通过开展竞赛深化产教融合，为人工智能、移动应用开发、区块链等产业培养技能人才。
            </p>
          </div>
        </div>
      </div>
      <!-- 合作企业 -->
      <div class="partnerSch">
        <p>合作企业</p>
        <p class="cont">与各大院校建立长期官方合作关系 为学生提供个性化、高效、高品质的学生生涯</p>
        <div class="sch_list">
          <div class="item">
            <img :src="require('@/assets/basic/qy01.png')" alt="">
          </div>
          <div class="item">
            <img :src="require('@/assets/basic/qy02.png')" alt="">
          </div>
          <div class="item">
            <img :src="require('@/assets/basic/qy03.png')" alt="">
          </div>
          <div class="item">
            <img :src="require('@/assets/basic/qy04.png')" alt="">
          </div>
          <div class="item">
            <img :src="require('@/assets/basic/qy05.png')" alt="">
          </div>
          <div class="item">
            <img :src="require('@/assets/basic/qy06.png')" alt="">
          </div>
          <div class="item">
            <img :src="require('@/assets/basic/qy07.png')" alt="">
          </div>
          <div class="item">
            <img :src="require('@/assets/basic/qy08.png')" alt="">
          </div>
          <div class="item">
            <img :src="require('@/assets/basic/qy09.png')" alt="">
          </div>
          <div class="item">
            <img :src="require('@/assets/basic/qy10.png')" alt="">
          </div>
        </div>
      </div>
    </div>
    <Logo />
</div>
</template>

<script>
import Conterheader from "@/components/common/ConterHeader";
import Logo from "@/components/common/Logo"
export default {
  name: "schoolLife",
  components: {
    Conterheader,
    Logo
  },
  data() {
    return {
      typeIndex: 0,
      activeIndex: 0,
      companyList: [
        {
          title: "腾讯",
          num: 253,
          logoUrl: require("@/assets/basic/company01.png"),
          content:
            "公司概述公司概述公司概述公司概述公司概述公司概述公司概述公司概述",
        },
        {
          title: "小米",
          num: 13,
          logoUrl: require("@/assets/basic/company02.png"),
          content:
            "公司概述公司概述公司概述公司概述公司概述公司概述公司概述公司概述",
        },
        {
          title: "华为",
          num: 26,
          logoUrl: require("@/assets/basic/company03.png"),
          content:
            "公司概述公司概述公司概述公司概述公司概述公司概述公司概述公司概述",
        },
        {
          title: "OPPO",
          num: 135,
          logoUrl: require("@/assets/basic/company04.png"),
          content:
            "公司概述公司概述公司概述公司概述公司概述公司概述公司概述公司概述",
        },
        {
          title: "字节跳动",
          num: 19,
          logoUrl: require("@/assets/basic/company05.png"),
          content:
            "公司概述公司概述公司概述公司概述公司概述公司概述公司概述公司概述",
        },
        {
          title: "阿里巴巴",
          num: 3,
          logoUrl: require("@/assets/basic/company06.png"),
          content:
            "公司概述公司概述公司概述公司概述公司概述公司概述公司概述公司概述",
        },
        {
          title: "京东",
          num: 139,
          logoUrl: require("@/assets/basic/company07.png"),
          content:
            "公司概述公司概述公司概述公司概述公司概述公司概述公司概述公司概述",
        },
        {
          title: "拼多多",
          num: 38,
          logoUrl: require("@/assets/basic/company08.png"),
          content:
            "公司概述公司概述公司概述公司概述公司概述公司概述公司概述公司概述",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 1200px;
  margin: 20px auto;
  .nav {
    margin: 20px auto 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .nav-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      width: 285px;
      height: 68px;
      border-radius: 10px;
      background: #ccc;
      > span {
        font-size: 16px;
        color: #fff;
      }
      > div {
        display: flex;
        align-items: center;
        .tips {
          margin-right: 10px;
          padding: 6px;
          font-size: 14px;
          color: #fff;
          border: 1px solid #fff;
          border-radius: 6px;
        }
        > span {
          color: #fff;
        }
      }
      &:nth-child(1) {
        background: url("../assets/basic/company_nav_bg01.png") center no-repeat;
      }
      &:nth-child(2) {
        background: url("../assets/basic/company_nav_bg02.png") center no-repeat;
      }
      &:nth-child(3) {
        background: url("../assets/basic/company_nav_bg03.png") center no-repeat;
      }
      &:nth-child(4) {
        background: url("../assets/basic/company_nav_bg04.png") center no-repeat;
      }
    }
  }
  .future {
    > p {
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
    }
    .future_cont {
      display: flex;
      margin: 60px auto 0;
      background: url(../assets/basic/company_bg.png) no-repeat center;
      .cont_nav {
        position: relative;
        width: 300px;
        height: 310px;
        text-align: center;
        background: url("../assets/basic/school_icon_bg.png") center no-repeat;
        .nav_item {
          position: absolute;
          top: 0%;
          left: 50%;
          transform: translateX(-50%);
          width: 150px;
          height: 120px;
          &.active {
            background: url("../assets/basic/school_icon_active.png") center
              no-repeat;
              >span {
                color: #bd271b;
                font-weight: 600;
              }
          }
          > i {
            margin: 0 auto 5px;
            display: block;
            width: 36px;
            height: 36px;
            background: url("../assets/basic/school_icon1.png");
          }
          font-size: 14px;
          &:nth-child(2) {
            position: absolute;
            top: 25%;
            left: 25%;
            > i {
              width: 36px;
              height: 34px;
              background: url("../assets/basic/school_icon2.png");
            }
          }
          &:nth-child(3) {
            position: absolute;
            top: 25%;
            left: 75%;
            > i {
              width: 36px;
              height: 36px;
              background: url("../assets/basic/school_icon3.png");
            }
          }
          &:nth-child(4) {
            position: absolute;
            top: 50%;
            right: 25%;
            > i {
              width: 36px;
              height: 30px;
              background: url("../assets/basic/school_icon4.png");
            }
          }
        }
      }
      .cont_body {
        margin-left: 70px;
        width: 700px;
        .body_item {
          .txt {
            position: relative;
            height: 144px;
            padding: 30px;
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
            &::before {
              content: "";
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              width: 4px;
              height: 144px;
              background: #bd271b;
            }
            > span {
              font-size: 24px;
              font-weight: 600;
            }
            > p {
              font-size: 14px;
              color: #939393;
            }
          }
          .icon {
            display: flex;
            flex-wrap: wrap;
            width: 660px;
            background: #f5f9fa;
            margin: 0 auto;
            padding: 10px 30px;
            .item {
              margin: 12px 0;
              width: 25%;
              display: flex;
              color: #4e4e4e;
              font-size: 14px;
              > i {
                display: block;
                width: 24px;
                height: 24px;
                margin-right: 10px;
                background: url("../assets/basic/icon01.png");
              }
              &:nth-child(2) {
                > i {
                  background: url("../assets/basic/icon02.png");
                }
              }
              &:nth-child(3) {
                > i {
                  height: 22px;
                  background: url("../assets/basic/icon03.png");
                }
              }
              &:nth-child(4) {
                > i {
                  height: 20px;
                  background: url("../assets/basic/icon04.png");
                }
              }
              &:nth-child(5) {
                > i {
                  background: url("../assets/basic/icon05.png");
                }
              }
              &:nth-child(6) {
                > i {
                  background: url("../assets/basic/icon06.png");
                }
              }
              &:nth-child(7) {
                > i {
                  background: url("../assets/basic/icon07.png");
                }
              }
              &:nth-child(8) {
                > i {
                  background: url("../assets/basic/icon07.png");
                }
              }
            }
          }
        }
      }
    }
  }
  .scene {
    margin: 60px auto 40px;
    > p {
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
    }
    .scene_body {
      display: flex;
      justify-content: space-between;
      .body_item {
        display: flex;
        flex-direction: column;
        width: 250px;
        padding: 20px;
        border: 1px solid #eaeff3;
        border-radius: 10px;
        > img {
          width: 70px;
          height: 70px;
        }
        > span {
          margin: 20px 0;
          font-size: 20px;
          font-weight: 600;
        }
        > p {
          margin: 0;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #939393;
          line-height: 20px;
        }
      }
    }
  }
  .partnerSch {
    background: #FAFAFA;
    padding: 40px;
    border-radius: 10px;
    font-family: PingFangSC-Semibold, PingFang SC;
    >p {
      margin: 0;
      font-size: 30px;
      font-weight: 600;
      color: #222222;
    }
    .cont {
      margin: 20px 0 40px;
      font-size: 14px;
      color: #939393;
      font-weight: 200;
    }
    .sch_list {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 20px 0;
        >img {
          max-width: 192px;
          max-height: 70px;
        }
      }
    }
  }
}
</style> 