<template>
    <div class="wrapper">
        <div class="logo">
            <div class="logo_item">
                <img class="logo_icon" :src="require('@/assets/basic/product_logo01.png')" alt="">
                <span>安青前程APP</span>
                <span class="slogan">安青前程的slogan</span>
            </div>
            <div class="logo_item">
                <img class="logo_icon" :src="require('@/assets/basic/product_logo02.png')" alt="">
                <span>安青前程第二课堂APP</span>
                <span class="slogan">安青前程第二课堂的slogan</span>
            </div>
            <div class="logo_item">
                <img :src="require('@/assets/basic/footer_wem.png')" alt="">
                <span class="slogan">安青前程第二课堂的slogan</span>
            </div>
            <div class="logo_item">
                <img :src="require('@/assets/basic/footer_wem.png')" alt="">
                <span class="slogan">安青前程第二课堂的slogan</span>
            </div>
        </div>
        <div class="product_list">
            <div class="product_item">
                <span>大国工匠</span>
                <div>
                    <div class="tips">Web</div>
                    <span>></span>
                </div>
            </div>
            <div class="product_item">
                <span>CEO运营实战平台</span>
                <div>
                    <div class="tips">Web</div>
                    <span>></span>
                </div>
            </div>
            <div class="product_item">
                <span>菁星商城企业端</span>
                <div>
                    <div class="tips">Web</div>
                    <span>></span>
                </div>
            </div>
            <div class="product_item">
                <span>菁星校园报修服务平台</span>
                <div>
                    <div class="tips">Web</div>
                    <span>></span>
                </div>
            </div>
            <div class="product_item">
                <span>通信大厅后台</span>
                <div>
                    <div class="tips">Web</div>
                    <span>></span>
                </div>
            </div>
            <div class="product_item">
                <span>网络大厅后台</span>
                <div>
                    <div class="tips">Web</div>
                    <span>></span>
                </div>
            </div>
            <div class="product_item">
                <span>第二课堂高校端</span>
                <div>
                    <div class="tips">Web</div>
                    <span>></span>
                </div>
            </div>
            <div class="product_item">
                <span>职教中心</span>
                <div>
                    <div class="tips">Web</div>
                    <span>></span>
                </div>
            </div>
            <div class="product_item">
                <span>菁星赛事</span>
                <div>
                    <div class="tips">Web</div>
                    <span>></span>
                </div>
            </div>
            <div class="product_item">
                <span>菁星赛事后台</span>
                <div>
                    <div class="tips">Web</div>
                    <span>></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'product'
}
</script>
<style lang="scss" scoped>
.wrapper {
    width: 1200px;
    margin: 60px auto;
    .logo {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        .logo_item {
            width: 50%;
            margin-bottom: 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
            >img {
                max-width: 140px;
                min-width: 80px;
                width: 100%;
                margin-bottom: 20px;
                &.logo_icon {
                    width: 80px;
                }
            }
            >span {
                font-size: 24px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                margin-bottom: 20px;
                color: #222222;
            }
            .slogan {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #6D7385;
            }
        }
    }
    .product_list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        .product_item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;
            margin: 20px 50px;
            width: 400px;
            height: 50px;
            border-radius: 10px;
            transition: 0.5s all ease;
            &:hover {
                transform: translateY(-5px);
                box-shadow: 0px 4px 10px -2px rgb(0 0 0 / 10%);
            }
            > span {
                font-size: 16px;
                color: #fff;
            }
            > div {
                display: flex;
                align-items: center;
                .tips {
                margin-right: 10px;
                padding: 6px;
                font-size: 14px;
                color: #fff;
                border: 1px solid #fff;
                border-radius: 6px;
                }
                > span {
                color: #fff;
                }
            }
            &:nth-child(1) {
                background: url('../assets/basic/product_bg01.png') center no-repeat;
            }
            &:nth-child(2) {
                background: url('../assets/basic/product_bg02.png') center no-repeat;
            }
            &:nth-child(3) {
                background: url('../assets/basic/product_bg03.png') center no-repeat;
            }
            &:nth-child(4) {
                background: url('../assets/basic/product_bg04.png') center no-repeat;
            }
            &:nth-child(5) {
                background: url('../assets/basic/product_bg05.png') center no-repeat;
            }
            &:nth-child(6) {
                background: url('../assets/basic/product_bg06.png') center no-repeat;
            }
            &:nth-child(7) {
                background: url('../assets/basic/product_bg07.png') center no-repeat;
            }
            &:nth-child(8) {
                background: url('../assets/basic/product_bg08.png') center no-repeat;
            }
            &:nth-child(9) {
                background: url('../assets/basic/product_bg09.png') center no-repeat;
            }
            &:nth-child(10) {
                background: url('../assets/basic/product_bg10.png') center no-repeat;
            }
        }
    }
}
</style>