<template>
  <div class="home">
    <div class="nav-header">
      <div class="nav-item">
        <span class="icon-by"></span>
        <div>
          <span class="txt">我是在校/毕业学生</span>
          <span class="con">安青前程让学生主动学习，教学方法是什么？</span>
           <router-link to="/schoolLife"><span class="tips">></span></router-link>
        </div>
      </div>
      <div class="nav-item">
        <span class="icon-gx"></span>
        <div>
          <span class="txt">我是高职院校</span>
          <span class="con">安青前程能让学生主动学习，教学方法是什么？</span>
          <router-link to="/teach"><span class="tips">></span></router-link>
        </div>
      </div>
      <div class="nav-item">
        <span class="icon-qy"></span>
        <div>
          <span class="txt">我是企业机构</span>
          <span class="con">安青前程能让学生主动学习，教学方法是什么？</span>
           <router-link to="/company"><span class="tips">></span></router-link>
        </div>
      </div>
    </div>
    <div class="box1">
      <span class="txt5">新一代智能校园产品，开启数智校园新生态</span>
      <span class="paragraph1">
        安青前程以学生成长体系为模型，聚合校内外优质内容和应用，
        <br />
        为高校大学生提供学习、生活、就业等全方位优质服务
      </span>
      <div class="main3 flex-row">
        <div class="wrap1 flex-col">
          <div class="outer5 flex-col">
            <span class="info3">聚合校内外优质内容和应用</span>
          </div>
          <div class="outer6 flex-col">
            <span class="word4">
              共建高校人才供给与企业人才前置培养闭环场景
            </span>
          </div>
          <div class="outer7 flex-col">
            <span class="info4">提供学习、生活、就业等全方位优质服务</span>
          </div>
        </div>
        <div class="wrap2 flex-col">
          <div class="bd2 flex-col">
            <div class="wrap3 flex-col"></div>
            <img
              class="pic2"
              referrerpolicy="no-referrer"
              :src="require('@/assets/basic/home_mobile.png')"
            />
          </div>
        </div>
        <div class="wrap4 flex-col">
          <div class="wrap5 flex-col">
            <span class="word5">升级大学生创业就业服务体系</span>
          </div>
          <div class="wrap6 flex-col">
            <span class="word6">
              共建高校人才供给与企业人才前置培养闭环场景
            </span>
          </div>
          <div class="wrap7 flex-col">
            <span class="info5">
              构建学校、学生、企业三位一体的协同育人新模式
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="box2">
      <div class="main4 flex-col">
        <div class="group2 flex-row">
          <span class="word7">互联互通&nbsp;·&nbsp;数据共享</span>
        </div>
        <div class="group3 flex-row">
          <span class="paragraph2">
            安青前程以学生成长体系为模型，聚合校内外优质内容和应用，
            <br />
            为高校大学生提供学习、生活、就业等全方位优质服务
          </span>
        </div>
        <div style="margin: 20px auto; position: relative">
          <div class="group4 flex-row">
            <div class="outer8 flex-col">
              <div class="mod3 flex-col">
                <span class="txt6">职教中心</span>
              </div>
            </div>
            <div class="outer9 flex-col">
              <div class="layer4 flex-col">
                <span class="info6">大国工匠</span>
              </div>
            </div>
          </div>
          <div class="group5 flex-col">
            <div class="bd3 flex-col"><span class="word8">第二课堂</span></div>
          </div>
          <div class="group6 flex-col"></div>
          <div class="group7 flex-col"></div>
          <div class="group8 flex-col">
            <div class="outer10 flex-col">
              <span class="paragraph3">
                创新创业/
                <br />
                技能大赛
              </span>
            </div>
          </div>
          <div class="group9 flex-col"></div>
          <div class="group10 flex-col"></div>
          <div class="group11 flex-col">
            <div class="block4 flex-col"></div>
            <div class="block5 flex-col"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="box3">
      <span class="word9">校企合作</span>
      <span class="infoBox1">
        安青前程以学生成长体系为模型，聚合校内外优质内容和应用，
        <br />
        为高校大学生提供学习、生活、就业等全方位优质服务
      </span>
      <div class="main5 flex-row">
       <img :src="require('@/assets/basic/qiye.png')" alt="" style="margin:0 auto;">
      </div>
    </div>
    <div class="box4">
      <div class="main6 flex-col">
        <div class="wrap8 flex-row"><span class="txt7">职教中心</span></div>
        <div class="wrap9 flex-row">
          <span class="infoBox6">
            安青前程以学生成长体系为模型，聚合校内外优质内容和应用，
            <br />
            为高校大学生提供学习、生活、就业等全方位优质服务
          </span>
        </div>
        <img :src="require('@/assets/basic/teach_content01.png')" alt="" style="margin: 20px auto; width:1122px;height:625px">
      </div>
    </div>
    <div class="box5">
      <span class="txt22">核心功能</span>
      <span class="paragraph10">
        安青前程以学生成长体系为模型，聚合校内外优质内容和应用，
        <br />
        为高校大学生提供学习、生活、就业等全方位优质服务
      </span>
      <div class="main16 flex-row">
        <div
          class="group21 flex-col"
          v-for="(item, index) in loopData0"
          :key="index"
        >
          <div class="main17 flex-col">
            <span class="info10" v-html="item.lanhutext0"></span>
            <div class="section10 flex-col"></div>
            <span class="word26" v-html="item.lanhutext1"></span>
            <button class="section11 flex-col">
              <span class="word27" v-html="item.lanhutext2"></span>
            </button>
          </div>
          <img :src="require('@/assets/basic/kernel.png')" alt="">
        </div>
      </div>
    </div>
    <div class="box6">
      <div class="main18 flex-col">
        <div class="layer5 flex-row">
          <span class="word28">校招直通车</span>
        </div>
        <div class="layer6 flex-row">
          <div class="layer8 flex-col" :class="activeIndex == 0?'active':''" @click="activeIndex = 0"><span class="txt23" >推荐名企</span></div>
          <div class="layer8 flex-col" :class="activeIndex == 1?'active':''" @click="activeIndex = 1"><span class="txt23" >推荐岗位</span></div>
        </div>
        <div class="item" v-show="activeIndex == 0">
          <div class="item-box" v-for="(item,index) in companyList" :key="index">
            <span>在找职位{{item.num}}个</span>
            <div class="logo">
              <img :src="item.logoUrl"  alt="">
            </div>
            <div>
              <p>{{item.title}}</p>
              <p class="con">{{item.content}}</p>
              <div class="info">查看详情</div>
            </div>
          </div>
        </div>
        <div class="item" v-show="activeIndex == 1">
          <div class="item-boxSty2" v-for="index in 8" :key="index">
          <span>市场策划</span>
          <div class="money">10k-15k*13薪</div>
          <div class="exe">经验1-3年 / 大专</div>
          <div class="tag">
            <span>扁平管理</span>
            <span>扁平管理</span>
            <span>扁平管理</span>
          </div>

          <div class="company">
            <div class="logo">
              <img :src="require('@/assets/basic/company01.png')" alt="" />
              <span>腾讯</span>
            </div>
            <div class="info">查看详情</div>
          </div>
        </div>
        </div>
      </div>
    </div>
    <div class="box7">
      <div class="main19 flex-row">
        <span class="info17">最新资讯</span>
        <div class="layer28 flex-col">
          <div class="box14 flex-row"  @click="$router.push('article')">
            <!-- <img
              class="icon2"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng3bfa574113f9dfcba7ef66d8829220e76cdc0b085fcb83bfdb8d4ad4d9ba5fe5.png"
            /> -->
            <span class="word46">
              <img :src="require('@/assets/basic/search.png')" alt="">
              查看全部</span>
          </div>
        </div>
      </div>
      <div class="message-info">
        <div class="mes-item" v-for="index in 20" :key="index" @click="$router.push('article')">
          <div>
            <el-tag style="margin:0 5px" size="mini">超小标签</el-tag> 
            <span class="title">无人机应用技术专业校企共建</span>
          </div>
          <span class="time">2021-4-26</span>
        </div>
      </div>
    </div>
    <div class="box8">
      <div class="main32 flex-col">
        <div class="box15 flex-col">
          <span class="word76">全国合作院校与企业</span>
          <span class="word77">
            与各大院校建立长期官方合作关系&nbsp;为学生提供个性化、高效、高品质的学生生涯
          </span>
          <div class="block10 flex-col">
            <div class="layer29 flex-row">
              <div class="bd12 flex-col">
                <div class="mod25 flex-col"></div>
              </div>
              <div class="bd13 flex-col">
                <div class="outer14 flex-col"></div>
              </div>
              <div class="bd14 flex-col">
                <div class="main33 flex-col"></div>
              </div>
              <div class="bd15 flex-col">
                <div class="main34 flex-col"></div>
              </div>
              <div class="bd16 flex-col">
                <div class="block11 flex-col"></div>
              </div>
            </div>
            <div class="layer30 flex-row">
              <span class="word78">蚌埠学院</span>
              <span class="word79">安徽科技学院</span>
              <span class="txt47">合肥师范学院</span>
              <span class="word80">安庆师范大学</span>
              <span class="txt48">淮南师范学院</span>
            </div>
          </div>
          <div class="block12 flex-row">
            <img
              class="pic5"
              referrerpolicy="no-referrer"
              :src="require('@/assets/basic/qy01.png')"
            />
            <img
              class="img6"
              referrerpolicy="no-referrer"
              :src="require('@/assets/basic/qy02.png')"
            />
            <img
              class="img7"
              referrerpolicy="no-referrer"
              :src="require('@/assets/basic/qy03.png')"
            />
            <img
              class="pic6"
              referrerpolicy="no-referrer"
              :src="require('@/assets/basic/qy04.png')"
            />
            <img
              class="img8"
              referrerpolicy="no-referrer"
              :src="require('@/assets/basic/qy05.png')"
            />
          </div>
          <div class="block13 flex-row">
            <img
              class="img9"
              referrerpolicy="no-referrer"
              :src="require('@/assets/basic/qy06.png')"
            />
            <img
              class="img10"
              referrerpolicy="no-referrer"
              :src="require('@/assets/basic/qy07.png')"
            />
            <img
              class="pic7"
              referrerpolicy="no-referrer"
              :src="require('@/assets/basic/qy08.png')"
            />
            <img
              class="img11"
              referrerpolicy="no-referrer"
              :src="require('@/assets/basic/qy09.png')"
            />
            <img
              class="img12"
              referrerpolicy="no-referrer"
              :src="require('@/assets/basic/qy10.png')"
            />
          </div>
        </div>
      </div>
    </div>
    <Logo/>
  </div>
</template>

<script>
// @ is an alias to /src
import Logo from '@/components/common/Logo'

export default {
  name: "Home",
  components: {
    Logo
  },
  data() {
    return {
       activeIndex:0,
       loopData0: [
        {
          lanhutext0: '职教中心',
          lanhutext1:
            '安青前程以学生成长体系为模型，聚合校内外优质内容和应用，为高校大学生提供学习、生活、就业等全方位优质服务',
          lanhutext2: '查看详情'
        },
        {
          lanhutext0: '第二课堂',
          lanhutext1:
            '安青前程以学生成长体系为模型，聚合校内外优质内容和应用，为高校大学生提供学习、生活、就业等全方位优质服务',
          lanhutext2: '查看详情'
        },
        {
          lanhutext0: '大国工匠',
          lanhutext1:
            '安青前程以学生成长体系为模型，聚合校内外优质内容和应用，为高校大学生提供学习、生活、就业等全方位优质服务',
          lanhutext2: '查看详情'
        },
        {
          lanhutext0: '创新创业/技能大赛',
          lanhutext1:
            '安青前程以学生成长体系为模型，聚合校内外优质内容和应用，为高校大学生提供学习、生活、就业等全方位优质服务',
          lanhutext2: '查看详情'
        }
      ],
      companyList:[
        {
          title:'腾讯',
          num:253,
          logoUrl:require('@/assets/basic/company01.png'),
          content:'公司概述公司概述公司概述公司概述公司概述公司概述公司概述公司概述'
        },
        {
          title:'小米',
          num:13,
          logoUrl:require('@/assets/basic/company02.png'),
          content:'公司概述公司概述公司概述公司概述公司概述公司概述公司概述公司概述'
        },
        {
          title:'华为',
          num:26,
          logoUrl:require('@/assets/basic/company03.png'),
          content:'公司概述公司概述公司概述公司概述公司概述公司概述公司概述公司概述'
        },
        {
          title:'OPPO',
          num:135,
          logoUrl:require('@/assets/basic/company04.png'),
          content:'公司概述公司概述公司概述公司概述公司概述公司概述公司概述公司概述'
        },
        {
          title:'字节跳动',
          num:19,
          logoUrl:require('@/assets/basic/company05.png'),
          content:'公司概述公司概述公司概述公司概述公司概述公司概述公司概述公司概述'
        },
        {
          title:'阿里巴巴',
          num:3,
          logoUrl:require('@/assets/basic/company06.png'),
          content:'公司概述公司概述公司概述公司概述公司概述公司概述公司概述公司概述'
        },
        {
          title:'京东',
          num:139,
          logoUrl:require('@/assets/basic/company07.png'),
          content:'公司概述公司概述公司概述公司概述公司概述公司概述公司概述公司概述'
        },
        {
          title:'拼多多',
          num:38,
          logoUrl:require('@/assets/basic/company08.png'),
          content:'公司概述公司概述公司概述公司概述公司概述公司概述公司概述公司概述'
        }
      ]
    }
  }
};
</script>
<style lang="scss" scoped>
.home {
  position: relative;
  height: auto;
  .nav-header {
    display: flex;
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    width: 1200px;
    height: 200px;
    margin: 0 auto;
    background-color: #fff;
    z-index: 1000;
    border-radius: 10px;
    border: 1px solid #eaeff3;
    box-shadow: 0px 4px 10px -2px rgb(0 0 0 / 10%);
    .nav-item {
      display: flex;
      flex: 1;
      padding: 30px;
      > .icon-by,
      .icon-gx,
      .icon-qy {
        display: block;
        width: 50px;
        height: 50px;
        margin-right: 20px;
        border-radius: 25px;
        background-color: rosybrown;
      }
      .icon-by {
        background: url(../assets/basic/home_std.png) no-repeat center;
      };
      .icon-gx {
        background: url(../assets/basic/home_sch.png) no-repeat center;
      }
      .icon-qy {
        background: url(../assets/basic/home_com.png) no-repeat center;
      }
      > div {
        flex: 1;
        position: relative;
        .txt {
          display: block;
          overflow-wrap: break-word;
          color: #222222;
          font-size: 24px;
          font-family: PingFangSC-Semibold;
          white-space: nowrap;
          line-height: 24px;
          height: 40px;
          text-align: left;
        }
        .con {
          width: 270px;
          height: 40px;
          display: block;
          overflow-wrap: break-word;
          color: #8e8e8e;
          font-size: 14px;
          line-height: 20px;
          text-align: justify;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .tips {
          position: absolute;
          right: 0;
          bottom: 0;
          display: inline-block;
          width: 36px;
          height: 36px;
          line-height: 36px;
          font-weight: 600;
          text-align: center;
          color: #bd271b;
          background-color: #fbf2f2;
          border-radius: 6px;
          transition: 0.6s all ease;
          &:hover {
            transform: translateY(-3px);
            color: #fff;
            background-color: #bd271b;
          }
        }
      }
    }
  }
  .box1 {
    padding-top: 350px;
    .txt5 {
      margin: 0 auto;
      width: 570px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(189, 39, 27, 1);
      font-size: 30px;
      font-family: PingFangSC-Semibold;
      white-space: nowrap;
      line-height: 30px;
      text-align: justify;
      align-self: center;
    }
    .paragraph1 {
      margin: 0 auto;
      width: 392px;
      height: 40px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(147, 147, 147, 1);
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      align-self: center;
      margin-top: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .main3 {
      width: 1128px;
      height: 320px;
      justify-content: space-between;
      margin: 60px auto 0;
      .wrap1 {
        width: 384px;
        height: 186px;
        margin-top: 60px;
        .outer5 {
          height: 42px;
          border-radius: 0 21px 21px 0;
          background-image: linear-gradient(90deg, #fff, #fbf3f2);
          align-self: flex-end;
          width: 239px;
          justify-content: center;
          align-items: flex-end;
          padding-right: 20px;
          .info3 {
            width: 192px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(34, 34, 34, 1);
            font-size: 16px;
            font-family: PingFangSC-Semibold;
            white-space: nowrap;
            line-height: 22px;
            text-align: right;
          }
        }
        .outer6 {
          height: 42px;
          border-radius: 21px;
          margin-top: 30px;
          width: 384px;
          border-radius: 0 21px 21px 0;
          background-image: linear-gradient(90deg, #fff, #fbf3f2);
          justify-content: center;
          align-items: flex-end;
          padding-right: 20px;
          .word4 {
            width: 336px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(34, 34, 34, 1);
            font-size: 16px;
            font-family: PingFangSC-Semibold;
            white-space: nowrap;
            line-height: 22px;
            text-align: right;
          }
        }
        .outer7 {
          height: 42px;
          border-radius: 21px;
          margin-top: 30px;
          width: 384px;
          border-radius: 0 21px 21px 0;
          background-image: linear-gradient(90deg, #fff, #fbf3f2);
          justify-content: center;
          align-items: flex-end;
          padding-right: 20px;
          .info4 {
            width: 288px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(34, 34, 34, 1);
            font-size: 16px;
            font-family: PingFangSC-Semibold;
            white-space: nowrap;
            line-height: 22px;
            text-align: right;
          }
        }
      }
      .wrap2 {
        height: 320px;
        border-radius: 30px 30px 0 0;
        background-color: rgba(0, 0, 0, 0.06);
        width: 280px;
        justify-content: flex-end;
        align-items: center;
        .bd2 {
          height: 310px;
          border-radius: 20px 20px 0 0;
          overflow: hidden;
          width: 260px;
          justify-content: flex-end;
          position: relative;
          .wrap3 {
            z-index: 1;
            width: 260px;
            height: 200px;
            background-image: linear-gradient(
              180deg,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 1) 100%
            );
          }
          .pic2 {
            position: absolute;
            left: 0;
            top: 0;
            width: 260px;
            height: 310px;
          }
        }
      }
      .wrap4 {
        width: 384px;
        height: 186px;
        margin-top: 60px;
        .wrap5 {
          height: 42px;
          border-radius: 21px 0 0 21px;
          background-image: linear-gradient(90deg, #fbf3f2, #fff);
          align-self: flex-start;
          width: 239px;
          justify-content: center;
          align-items: flex-end;
          padding-right: 11px;
          .word5 {
            width: 208px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(34, 34, 34, 1);
            font-size: 16px;
            font-family: PingFangSC-Semibold;
            white-space: nowrap;
            line-height: 22px;
            text-align: left;
          }
        }
        .wrap6 {
          height: 42px;
          border-radius: 21px 0 0 21px;
          background-image: linear-gradient(90deg, #fbf3f2, #fff);
          margin-top: 30px;
          width: 384px;
          justify-content: center;
          align-items: flex-start;
          padding-left: 20px;
          .word6 {
            width: 336px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(34, 34, 34, 1);
            font-size: 16px;
            font-family: PingFangSC-Semibold;
            white-space: nowrap;
            line-height: 22px;
            text-align: left;
          }
        }
        .wrap7 {
          height: 42px;
          border-radius: 21px 0 0 21px;
          background-image: linear-gradient(90deg, #fbf3f2, #fff);
          margin-top: 30px;
          width: 384px;
          justify-content: center;
          align-items: flex-end;
          padding-right: 12px;
          .info5 {
            width: 352px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(34, 34, 34, 1);
            font-size: 16px;
            font-family: PingFangSC-Semibold;
            white-space: nowrap;
            line-height: 22px;
            text-align: left;
          }
        }
      }
    }
  }
  .box2 {
    .main4 {
      z-index: 4;
      height: 830px;
      background-color: rgba(250, 250, 250, 1);
      margin-top: 60px;
      width: 100%;
      margin: 0 auto;
      position: relative;
      .group2 {
        z-index: auto;
        width: 275px;
        height: 30px;
        margin: 60px auto 0;
        .word7 {
          z-index: 5;
          width: 275px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(189, 39, 27, 1);
          font-size: 30px;
          font-family: PingFangSC-Semibold;
          white-space: nowrap;
          line-height: 30px;
          text-align: justify;
        }
      }
      .group3 {
        z-index: auto;
        width: 392px;
        height: 40px;
        margin: 20px auto 0;
        .paragraph2 {
          z-index: 6;
          width: 392px;
          height: 40px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(147, 147, 147, 1);
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .group4 {
        z-index: auto;
        width: 613px;
        height: 288px;
        justify-content: space-between;
        margin: 10px 0 0 -90px;
        .outer8 {
          z-index: 10;
          height: 208px;
          background: url(../assets/basic/home_skill.png) 0px -1px no-repeat;
          margin-top: 80px;
          width: 148px;
          justify-content: flex-end;
          align-items: flex-end;
          padding: 0 28px 87px 0;
          .mod3 {
            z-index: 94;
            height: 26px;
            border-radius: 12.5px 12.5px 12.5px 12.5px;
            border-width: 1px;
            border: 1px solid rgba(206, 59, 42, 1);
            background-color: rgba(255, 255, 255, 1);
            width: 80px;
            justify-content: center;
            align-items: center;
            .txt6 {
              z-index: 95;
              width: 56px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(189, 39, 27, 1);
              font-size: 14px;
              font-family: PingFangSC-Semibold;
              white-space: nowrap;
              line-height: 14px;
              text-align: center;
            }
          }
        }
        .outer9 {
          z-index: 224;
          height: 208px;
          background: url(../assets/basic/home_skill.png) 100% no-repeat;
          width: 148px;
          justify-content: flex-end;
          align-items: flex-end;
          padding: 0 28px 87px 0;
          .layer4 {
            z-index: 308;
            height: 26px;
            border-radius: 12.5px 12.5px 12.5px 12.5px;
            border-width: 1px;
            border: 1px solid rgba(206, 59, 42, 1);
            background-color: rgba(255, 255, 255, 1);
            width: 80px;
            justify-content: center;
            align-items: center;
            .info6 {
              z-index: 309;
              width: 56px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(189, 39, 27, 1);
              font-size: 14px;
              font-family: PingFangSC-Semibold;
              white-space: nowrap;
              line-height: 14px;
              text-align: center;
            }
          }
        }
      }
      .group5 {
        z-index: 117;
        height: 208px;
        background: url(../assets/basic/home_skill.png) 0px -1px no-repeat;
        width: 148px;
        justify-content: flex-end;
        align-items: flex-end;
        position: absolute;
        left: 30px;
        top: 20px;
        padding: 0 28px 87px 0;
        .bd3 {
          z-index: 201;
          height: 26px;
          border-radius: 12.5px 12.5px 12.5px 12.5px;
          border-width: 1px;
          border: 1px solid rgba(206, 59, 42, 1);
          background-color: rgba(255, 255, 255, 1);
          width: 80px;
          justify-content: center;
          align-items: center;
          .word8 {
            z-index: 202;
            width: 56px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(189, 39, 27, 1);
            font-size: 14px;
            font-family: PingFangSC-Semibold;
            white-space: nowrap;
            line-height: 14px;
            text-align: center;
          }
        }
      }
      .group6 {
        z-index: 310;
        position: absolute;
        left: 325px;
        top: 140px;
        width: 113px;
        height: 76px;
        background: url(../assets/basic/home_line.png) 0px -1px no-repeat;
      }
      .group7 {
        z-index: 203;
        position: absolute;
        top: 160px;
        left: 130px;
        width: 113px;
        height: 76px;
        background: url(../assets/basic/home_line2.png) 0px -1px no-repeat;
      }
      .group8 {
        z-index: 331;
        height: 208px;
        background: url(../assets/basic/home_skill.png) 0px -1px no-repeat;
        width: 148px;
        justify-content: flex-start;
        align-items: flex-end;
        position: absolute;
        left: 480px;
        top: 70px;
        padding: 75px 29px 0 0;
        .outer10 {
          z-index: 415;
          height: 46px;
          border-radius: 12.5px 12.5px 12.5px 12.5px;
          border-width: 1px;
          border: 1px solid rgba(206, 59, 42, 1);
          background-color: rgba(255, 255, 255, 1);
          width: 80px;
          justify-content: center;
          align-items: center;
          .paragraph3 {
            z-index: 416;
            width: 63px;
            height: 32px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(189, 39, 27, 1);
            font-size: 14px;
            font-family: PingFangSC-Semibold;
            line-height: 16px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .group9 {
        z-index: 417;
        position: absolute;
        left: 435px;
        top: 200px;
        width: 113px;
        height: 76px;
        background: url(../assets/basic/home_line.png) 0px -1px no-repeat;
      }
      .group10 {
        z-index: 96;
        position: absolute;
        left: 10px;
        top: 220px;
        width: 113px;
        height: 76px;
        background: url(../assets/basic/home_line2.png) 0px -1px no-repeat;
      }
      .group11 {
        z-index: 436;
        height: 465px;
        background: url(../assets/basic/home_basic.png) 0px -1px no-repeat;
        width: 760px;
        justify-content: flex-end;
        align-items: flex-start;
        position: absolute;
        left: 380px;
        transform: translateX(-50%);
        top: 145px;
        padding: 0 0 40px 1px;
        .block4 {
          z-index: 724;
          width: 241px;
          height: 268px;
          background: url(../assets/basic/home_basic2.png) 0px -1px no-repeat;
        }
        .block5 {
          z-index: 857;
          position: absolute;
          left: 210px;
          top: 379px;
          width: 150px;
          height: 66px;
          background: url(../assets/basic/home_line3.png) -1px 0px no-repeat;
        }
      }
    }
  }
  .box3 {
    .word9 {
     margin: 0 auto;
      width: 120px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(189, 39, 27, 1);
      font-size: 30px;
      font-family: PingFangSC-Semibold;
      white-space: nowrap;
      line-height: 30px;
      text-align: justify;
      align-self: center;
      margin-top: 60px;
    }
    .infoBox1 {
      margin: 0 auto;
      width: 392px;
      height: 40px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(147, 147, 147, 1);
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      align-self: center;
      margin-top: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .main5 {
      height: 430px;
      justify-content: space-between;
      margin: 40px auto;
    }
  }
  .box4 {
    .main6 {
      height: 870px;
      background-color: rgba(250, 250, 250, 1);
      margin-top: 60px;
      width: 100%;margin:0 auto;
      position: relative;
      .wrap8 {
        width: 120px;
        height: 30px;
        margin: 60px auto 0;
        .txt7 {
          width: 120px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(189, 39, 27, 1);
          font-size: 30px;
          font-family: PingFangSC-Semibold;
          white-space: nowrap;
          line-height: 30px;
          text-align: justify;
        }
      }
      .wrap9 {
        width: 392px;
        height: 40px;
        margin: 20px auto 0;
        .infoBox6 {
          width: 392px;
          height: 40px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(147, 147, 147, 1);
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .wrap10 {
        width: 1120px;
        height: 200px;
        justify-content: space-between;
        margin: 40px auto 0;
        .wrap11 {
          height: 200px;
          border-radius: 6px;
          background-color: rgba(189, 39, 27, 1);
          width: 60px;
          justify-content: center;
          align-items: center;
          .infoBox7 {
            width: 18px;
            height: 60px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            font-family: PingFangSC-Semibold;
            line-height: 30px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .wrap12 {
          width: 1040px;
          height: 200px;
          .bd5 {
            height: 40px;
            background: url(../assets/basic/home_box4_right.png) center no-repeat;
            background-size: 240px 48px;
            align-self: flex-end;
            width: 236px;
            justify-content: center;
            align-items: center;
            .word12 {
              width: 36px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 18px;
              font-family: PingFangSC-Semibold;
              white-space: nowrap;
              line-height: 18px;
              text-align: center;
            }
          }
          .bd6 {
            height: 140px;
            border-radius: 10px;
            border-width: 1px;
            border: 1px dashed rgba(189, 39, 27, 1);
            background-color: rgba(189, 39, 27, 0.1);
            margin-top: 20px;
            width: 1040px;
            justify-content: center;
            align-items: center;
            .box5 {
              width: 1000px;
              height: 100px;
              justify-content: space-between;
              .main7 {

                height: 100px;
                border-radius: 6px;
                background-color: rgba(255, 255, 255, 1);
                width: 196px;
                justify-content: center;
                align-items: center;
                .info7 {
                  width: 42px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(189, 39, 27, 1);
                  font-size: 14px;
                  white-space: nowrap;
                  line-height: 24px;
                  text-align: center;
                }
              }
              .main8 {
                height: 100px;
                border-radius: 6px;
                background-color: rgba(255, 255, 255, 1);
                width: 196px;
                justify-content: center;
                align-items: center;
                .word13 {
                  width: 28px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(189, 39, 27, 1);
                  font-size: 14px;
                  white-space: nowrap;
                  line-height: 24px;
                  text-align: center;
                }
              }
              .main9 {

                height: 100px;
                border-radius: 6px;
                background-color: rgba(255, 255, 255, 1);
                width: 196px;
                justify-content: center;
                align-items: center;
                .word14 {

                  width: 70px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(189, 39, 27, 1);
                  font-size: 14px;
                  white-space: nowrap;
                  line-height: 24px;
                  text-align: center;
                }
              }
              .main10 {

                height: 100px;
                border-radius: 6px;
                background-color: rgba(255, 255, 255, 1);
                width: 186px;
                justify-content: center;
                align-items: center;
                .word15 {

                  width: 56px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(189, 39, 27, 1);
                  font-size: 14px;
                  white-space: nowrap;
                  line-height: 24px;
                  text-align: center;
                }
              }
              .main11 {
                height: 100px;
                border-radius: 6px;
                background-color: rgba(255, 255, 255, 1);
                width: 146px;
                justify-content: center;
                align-items: center;
                .txt8 {
                  width: 56px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(189, 39, 27, 1);
                  font-size: 14px;
                  white-space: nowrap;
                  line-height: 24px;
                  text-align: center;
                }
              }
            }
          }
        }
      }
      .wrap13 {
        width: 1120px;
        height: 190px;
        justify-content: space-between;
        margin: 20px auto 0;
        .box6 {

          height: 190px;
          border-radius: 6px;
          background-color: rgba(74, 144, 226, 1);
          width: 60px;
          justify-content: center;
          align-items: center;
          .infoBox8 {

            width: 18px;
            height: 60px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            font-family: PingFangSC-Semibold;
            line-height: 30px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .box7 {
          width: 1040px;
          height: 190px;
          .block6 {
            z-index: 90;
            height: 40px;
            background: url(../assets/basic/home_box4_right2.png) -2px -4px
              no-repeat;
            align-self: flex-start;
            width: 236px;
            justify-content: center;
            align-items: center;
            .txt9 {
              width: 36px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 18px;
              font-family: PingFangSC-Semibold;
              white-space: nowrap;
              line-height: 18px;
              text-align: center;
            }
          }
          .block7 {

            height: 140px;
            border-radius: 10px;
            border-width: 1px;
            border: 1px dashed rgba(74, 144, 226, 1);
            background-color: rgba(74, 144, 226, 0.1);
            margin-top: 10px;
            width: 1040px;
            justify-content: center;
            align-items: center;
            .box8 {

              width: 1000px;
              height: 100px;
              justify-content: space-between;
              .mod7 {
 
                width: 196px;
                height: 100px;
                .main12 {

                  height: 40px;
                  border-radius: 6px;
                  background-color: rgba(255, 255, 255, 1);
                  width: 196px;
                  justify-content: center;
                  align-items: center;
                  .txt10 {
   
                    width: 147px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(74, 144, 226, 1);
                    font-size: 14px;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: center;
                  }
                }
                .main13 {
   
                  height: 40px;
                  border-radius: 6px;
                  background-color: rgba(255, 255, 255, 1);
                  margin-top: 20px;
                  width: 196px;
                  justify-content: center;
                  align-items: center;
                  .txt11 {

                    width: 112px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(74, 144, 226, 1);
                    font-size: 14px;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: center;
                  }
                }
              }
              .mod8 {

                width: 196px;
                height: 100px;
                .section3 {

                  height: 40px;
                  border-radius: 6px;
                  background-color: rgba(255, 255, 255, 1);
                  width: 196px;
                  justify-content: center;
                  align-items: center;
                  .word16 {

                    width: 56px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(74, 144, 226, 1);
                    font-size: 14px;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: center;
                  }
                }
                .section4 {

                  height: 40px;
                  border-radius: 6px;
                  background-color: rgba(255, 255, 255, 1);
                  margin-top: 20px;
                  width: 196px;
                  justify-content: center;
                  align-items: center;
                  .word17 {

                    width: 84px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(74, 144, 226, 1);
                    font-size: 14px;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: center;
                  }
                }
              }
              .mod9 {

                height: 100px;
                background: url(../assets/basic/home_box4_z1.png)
                  100% no-repeat;
                width: 196px;
                justify-content: center;
                align-items: center;
                .outer11 {

                  width: 56px;
                  height: 84px;
                  .txt12 {

                    width: 56px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(74, 144, 226, 1);
                    font-size: 14px;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: center;
                  }
                  .word18 {
                    width: 56px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(74, 144, 226, 1);
                    font-size: 14px;
                    white-space: nowrap;
                    line-height: 24px;
                    text-align: center;
                    margin-top: 36px;
                  }
                }
              }
              .mod10 {
   
                height: 100px;
                border-radius: 6px;
                background-color: rgba(255, 255, 255, 1);
                width: 186px;
                justify-content: center;
                align-items: center;
                .paragraph8 {
 
                  width: 56px;
                  height: 48px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(74, 144, 226, 1);
                  font-size: 14px;
                  line-height: 24px;
                  text-align: center;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
              .mod11 {

                height: 100px;
                border-radius: 6px;
                background-color: rgba(255, 255, 255, 1);
                width: 146px;
                justify-content: center;
                align-items: center;
                .word19 {

                  width: 56px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(74, 144, 226, 1);
                  font-size: 14px;
                  white-space: nowrap;
                  line-height: 24px;
                  text-align: center;
                }
              }
            }
          }
        }
      }
      .wrap14 {

        width: 1120px;
        height: 190px;
        justify-content: space-between;
        margin: 20px auto 0;
        .bd7 {

          height: 190px;
          border-radius: 6px;
          background-color: rgba(40, 184, 146, 1);
          width: 60px;
          justify-content: center;
          align-items: center;
          .paragraph9 {

            width: 18px;
            height: 120px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 18px;
            font-family: PingFangSC-Semibold;
            line-height: 30px;
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .bd8 {

          width: 1040px;
          height: 190px;
          .main14 {
            z-index: 90;
            height: 40px;
            background: url(../assets/basic/home_box4_right3.png) -2px -4px
              no-repeat;
            align-self: flex-start;
            width: 236px;
            justify-content: center;
            align-items: center;
            .txt13 {
              width: 36px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 18px;
              font-family: PingFangSC-Semibold;
              white-space: nowrap;
              line-height: 18px;
              text-align: center;
            }
          }
          .main15 {

            height: 140px;
            border-radius: 10px;
            border-width: 1px;
            border: 1px dashed rgba(40, 184, 146, 1);
            background-color: rgba(40, 184, 146, 0.1);
            margin-top: 10px;
            width: 1040px;
            justify-content: center;
            align-items: center;
            .box9 {

              width: 1000px;
              height: 100px;
              justify-content: space-between;
              .section5 {

                height: 100px;
                border-radius: 6px;
                background-color: rgba(255, 255, 255, 1);
                width: 196px;
                justify-content: center;
                align-items: center;
                .infoBox9 {
  
                  width: 84px;
                  height: 48px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(40, 184, 146, 1);
                  font-size: 14px;
                  line-height: 24px;
                  text-align: center;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
              .section6 {

                height: 100px;
                border-radius: 6px;
                background-color: rgba(255, 255, 255, 1);
                width: 196px;
                justify-content: center;
                align-items: center;
                .word20 {

                  width: 56px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(40, 184, 146, 1);
                  font-size: 14px;
                  white-space: nowrap;
                  line-height: 24px;
                  text-align: center;
                }
              }
              .section7 {

                height: 100px;
                border-radius: 6px;
                background-color: rgba(255, 255, 255, 1);
                width: 196px;
                justify-content: center;
                align-items: center;
                .txt14 {

                  width: 56px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(40, 184, 146, 1);
                  font-size: 14px;
                  white-space: nowrap;
                  line-height: 24px;
                  text-align: center;
                }
              }
              .section8 {

                height: 100px;
                border-radius: 6px;
                background-color: rgba(255, 255, 255, 1);
                width: 186px;
                justify-content: center;
                align-items: center;
                .txt15 {

                  width: 56px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(40, 184, 146, 1);
                  font-size: 14px;
                  white-space: nowrap;
                  line-height: 24px;
                  text-align: center;
                }
              }
              .section9 {

                height: 100px;
                border-radius: 6px;
                background-color: rgba(255, 255, 255, 1);
                width: 146px;
                justify-content: center;
                align-items: center;
                .word21 {

                  width: 56px;
                  display: block;
                  overflow-wrap: break-word;
                  color: rgba(40, 184, 146, 1);
                  font-size: 14px;
                  white-space: nowrap;
                  line-height: 24px;
                  text-align: center;
                }
              }
            }
          }
        }
      }
      .wrap15 {
        z-index: 90;
        height: 40px;
        background: url(../assets/basic/home_box4_right.png) center no-repeat;
        background-size: 240px 48px;
        width: 236px;
        justify-content: center;
        align-items: flex-start;
        position: absolute;
        left: 570px;
        top: 190px;
        .txt16 {
          margin: 0 auto;
          width: 36px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 18px;
          font-family: PingFangSC-Semibold;
          white-space: nowrap;
          line-height: 18px;
          text-align: center;
        }
      }
      .wrap16 {
        z-index: 91;
        height: 40px;
        background: url(../assets/basic/home_box4_right.png) center no-repeat;
        background-size: 240px 48px;
        width: 236px;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 798px;
        top: 190px;
        .txt17 {
          width: 36px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 18px;
          font-family: PingFangSC-Semibold;
          white-space: nowrap;
          line-height: 18px;
          text-align: center;
        }
      }
      .wrap17 {
        z-index: 1151;
        height: 40px;
        background: url(../assets/basic/home_box4_right.png) center no-repeat;
        background-size: 240px 48px;
        width: 236px;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 1008px;
        top: 190px;
        .word22 {
          z-index: 1152;
          width: 36px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 18px;
          font-family: PingFangSC-Semibold;
          white-space: nowrap;
          line-height: 18px;
          text-align: center;
        }
      }
      .wrap18 {
        z-index: 96;
        height: 40px;
        background: url(../assets/basic/home_box4_right.png) center no-repeat;
        background-size: 240px 48px;
        width: 236px;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 1218px;
        top: 190px;
        .word23 {
          width: 36px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 18px;
          font-family: PingFangSC-Semibold;
          white-space: nowrap;
          line-height: 18px;
          text-align: center;
        }
      }
      .wrap19 {
        z-index: 91;
        height: 40px;
        background: url(../assets/basic/home_box4_right2.png) -2px -4px
          no-repeat;
        width: 236px;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 798px;
        top: 410px;
        .word24 {
          width: 81px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 18px;
          font-family: PingFangSC-Semibold;
          white-space: nowrap;
          line-height: 18px;
          text-align: center;
        }
      }
      .wrap20 {
        z-index: 92;
        height: 40px;
        background: url(../assets/basic/home_box4_right2.png) -2px -4px
          no-repeat;
        width: 236px;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 1006px;
        top: 410px;
        .word25 {
          width: 36px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 18px;
          font-family: PingFangSC-Semibold;
          white-space: nowrap;
          line-height: 18px;
          text-align: center;
        }
      }
      .wrap21 {
        z-index: 93;
        height: 40px;
        background: url(../assets/basic/home_box4_right2.png) -2px -4px
          no-repeat;
        width: 236px;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 1213px;
        top: 410px;
        .info8 {
          width: 36px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 18px;
          font-family: PingFangSC-Semibold;
          white-space: nowrap;
          line-height: 18px;
          text-align: center;
        }
      }
      .wrap22 {
        z-index: 94;
        height: 40px;
        background: url(../assets/basic/home_box4_right2.png) -2px -4px
          no-repeat;
        width: 236px;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 1403px;
        top: 410px;
        .txt18 {
          width: 36px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 18px;
          font-family: PingFangSC-Semibold;
          white-space: nowrap;
          line-height: 18px;
          text-align: center;
        }
      }
      .wrap23 {
        z-index:97;
        height: 40px;
        background: url(../assets/basic/home_box4_right3.png) -2px -4px
          no-repeat;
        width: 236px;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 790px;
        top: 620px;
        .txt19 {
          width: 36px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 18px;
          font-family: PingFangSC-Semibold;
          white-space: nowrap;
          line-height: 18px;
          text-align: center;
        }
      }
      .wrap24 {
        z-index: 98;
        height: 40px;
        background: url(../assets/basic/home_box4_right3.png) -2px -4px
          no-repeat;
        width: 236px;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 1008px;
        top: 620px;
        .txt20 {
          width: 36px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 18px;
          font-family: PingFangSC-Semibold;
          white-space: nowrap;
          line-height: 18px;
          text-align: center;
        }
      }
      .wrap25 {
        z-index: 99;
        height: 40px;
        background: url(../assets/basic/home_box4_right3.png) -2px -4px
          no-repeat;
        width: 236px;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 1220px;
        top: 620px;
        .info9 {
          width: 36px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 18px;
          font-family: PingFangSC-Semibold;
          white-space: nowrap;
          line-height: 18px;
          text-align: center;
        }
      }
      .wrap26 {
        z-index: 100;
        height: 40px;
        background: url(../assets/basic/home_box4_right3.png) -2px -4px
          no-repeat;
        width: 236px;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: 1403px;
        top: 620px;
        .txt21 {
          width: 36px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(255, 255, 255, 1);
          font-size: 18px;
          font-family: PingFangSC-Semibold;
          white-space: nowrap;
          line-height: 18px;
          text-align: center;
        }
      }
    }
  }
  .box5 {
    .txt22 {
      margin: 0 auto;
      width: 120px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(189, 39, 27, 1);
      font-size: 30px;
      font-family: PingFangSC-Semibold;
      white-space: nowrap;
      line-height: 30px;
      text-align: center;
      align-self: center;
      margin-top: 60px;
    }
    .paragraph10 {
      margin: 0 auto;
      width: 392px;
      height: 40px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(147, 147, 147, 1);
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      align-self: center;
      margin-top: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .main16 {
      width: 1200px;
      height: 459px;
      justify-content: space-between;
      margin: 40px auto 0;
      .group21 {
        height: 460px;
        width: 300px;
        justify-content: flex-start;
        padding-top: 30px;
        align-items: center;
        position: relative;
        >img {
          position: absolute;
          right: 0px;
          bottom: 2px;
        }
        &:nth-child(2n) {
          background:  #ac0202;
        } 
        &:nth-child(2n+1) {
          background:  #bd271b;
        }
        &:first-child {
          border-radius: 5px 0 0 5px;
        }
        &:last-child {
          border-radius: 0 5px 5px 0;
        }
        .main17 {
          width: 240px;
          height: 390px;
          .info10 {
            width: 204px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(255, 255, 255, 1);
            font-size: 24px;
            font-family: PingFangSC-Semibold;
            white-space: nowrap;
            line-height: 30px;
            text-align: justify;
            align-self: flex-start;
          }
          .section10 {
            width: 20px;
            height: 4px;
            background-color: rgba(255, 255, 255, 1);
            align-self: flex-start;
            margin-top: 20px;
          }
          .word26 {
            width: 240px;
            height: 80px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(246, 246, 246, 0.8);
            font-size: 14px;
            line-height: 20px;
            text-align: justify;
            margin-top: 22px;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .section11 {
            height: 32px;
            border-radius: 6px;
            background-color: rgba(255, 255, 255, 1);
            align-self: flex-start;
            margin-top: 202px;
            width: 80px;
            justify-content: center;
            align-items: center;
            .word27 {
              width: 56px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(189, 39, 27, 1);
              font-size: 14px;
              white-space: nowrap;
              line-height: 14px;
              text-align: justify;
            }
          }
        }
      }
    }
  }
  .box6 {
     .main18 {
      height: 924px;
      background-color: rgba(250, 250, 250, 1);
      margin-top: 61px;
      width: 100%;margin:0 auto;
      position: relative;
      .layer5 {
        width: 150px;
        height: 30px;
        margin: 60px auto 0;
        .word28 {
          width: 150px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(189, 39, 27, 1);
          font-size: 30px;
          font-family: PingFangSC-Semibold;
          white-space: nowrap;
          line-height: 30px;
          text-align: justify;
        }
      }
      .layer6 {
        width: 340px;
        height: 50px;
        justify-content: space-between;
        margin: 40px auto 0;
        .layer8 {
          height: 50px;
          border-radius: 25px;
          border-width: 1px;
          border: 1px solid rgba(234, 239, 243, 1);
          background-color: rgba(255, 255, 255, 1);
          width: 150px;
          justify-content: center;
          align-items: center;
          .txt23 {
            width: 72px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(34, 34, 34, 1);
            font-size: 18px;
            white-space: nowrap;
            line-height: 18px;
            text-align: center;
          }
          &.active {
            background-color: rgba(34, 34, 34, 1);
            .txt23 {
              color: rgba(255, 255, 255, 1);
            }
          }
        }
      }
      .item {
        width: 1180px;
        margin: 20px auto 40px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .item-box {
          position: relative;
          width: 250px;
          height: 302px;
          margin: 20px;
          padding:0 20px 20px;
          border-radius: 10px;
          background-color: #fff;
          transition: 0.5s all  ease;
          border: 1px solid #EAEFF3;
          >span {
            position: absolute;
            left: 0;
            top: 0;
            height: 32px;
            line-height: 32px;
            padding:0 10px;
            border-radius: 10px  0 16px;
            color: #8e8e8e;
            background-color: #eee;
            z-index: 10;
          }
          .logo {
            position: relative;
            width: 100%;
            height: 120px;
            img {
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%,-50%);
              margin: 0 auto;
              height: auto;
            }
          }
          >div {
            p:first-child {
              color: #222222;
              font-weight: 600;
              font-size: 18px;
              text-align: center;
            }
            .con {
              overflow:hidden; 
              text-overflow:ellipsis;
              display:-webkit-box; 
              -webkit-box-orient:vertical;
              -webkit-line-clamp:2; 
            }
            .info {
              margin-top: 20px;
              width: 210px;
              height: 44px;
              line-height: 44px;
              color: #222;
              text-align: center;
              background-color: #fff;
              border-radius: 6px;
              border: 1px solid #eaeff3;
              &:hover {
                color: #fff;
                background-color: #bd271b;
              }
            }
          }
          &:hover {
            transform: translateY(-4px);
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
          }
        }
         .item-boxSty2 {
          position: relative;
          width: 250px;
          height: 302px;
          margin: 20px;
          padding: 20px;
          border-radius: 10px;
          background-color: #fff;
          transition: 0.5s all ease;
          border-radius: 10px;
          border: 1px solid #eaeff3;
          font-size: 18px;
          > span {
            font-weight: 600;
          }
          .money {
            color: #bd271b;
            margin: 14px 0;
          }
          .tag {
            margin: 10px auto 20px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            > span {
              font-size: 12px;
              padding: 3px 5px;
              border-radius: 4px;
              margin: 0 2px;
              border: 1px solid #eaeff3;
            }
          }
          .exe {
            font-size: 14px;
            color: #939393;
          }
          .company {
            .logo {
              display: flex;
              align-items: center;
              img {
                width: 40px;
                height: 40px;
                border-radius: 4px;
                margin-right: 20px;
                border: 1px solid #eaeff3;
              }
              > span {
                font-weight: 600;
              }
            }
            .info {
              margin-top: 20px;
              width: 210px;
              height: 44px;
              line-height: 44px;
              color: #222;
              text-align: center;
              background-color: #fff;
              border-radius: 6px;
              border: 1px solid #eaeff3;
              &:hover {
                color: #fff;
                background-color: #bd271b;
              }
            }
          }
          &:hover {
            transform: translateY(-4px);
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
          }
        }
      }
    }
  }
  .box7 {
    .main19 {
      width: 1120px;
      height: 44px;
      justify-content: space-between;
      margin: 60px auto 0;
      .info17 {
        width: 120px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(189, 39, 27, 1);
        font-size: 30px;
        font-family: PingFangSC-Semibold;
        white-space: nowrap;
        line-height: 30px;
        text-align: justify;
        margin-top: 7px;
      }
      .layer28 {
        height: 44px;
        border-radius: 6px;
        border-width: 1px;
        border: 1px solid rgba(189, 39, 27, 0.1);
        background-color: rgba(189, 39, 27, 0.06);
        width: 128px;
        justify-content: center;
        align-items: center;
        .box14 {
          width: 88px;
          height: 16px;
          justify-content: space-between;
          .icon2 {
            width: 16px;
            height: 15px;
          }
          .word46 {
            width: 64px;
            display: flex;
            align-items: center;
            overflow-wrap: break-word;
            color: rgba(179, 0, 0, 1);
            font-size: 16px;
            white-space: nowrap;
            line-height: 16px;
            text-align: center;
            >img {
              margin-right: 5px;
              display: block;
            }
          }
        }
      }
    }
    .message-info {
      width: 1120px;
      margin: 40px auto 60px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .mes-item {
        display: flex;
        width: 48%;
        margin: 12px 0;
        justify-content: space-between;
        transition: all 0.3s ease;
        align-items: center;
    padding: 0 5px;
    border-radius: 5px;
        .title {
          margin: 0 5px;
          color: #222;
          font-size: 16px;
        }
        .time {color: #AFAFAF;}
        &:hover {
          transform: translateY(-5px);
          box-shadow: 0px 4px 10px 0px rgb(0 0 0 / 6%);
          .title {
            color: #bd271b;
          }
        } 
      }
    }
  }
  .box8 {
    .main32 {
      height: 700px;
      background-color: rgba(250, 250, 250, 1);
      margin-top: 60px;
      width: 100%;margin:0 auto;
      justify-content: center;
      align-items: center;
      .box15 {
        width: 1200px;
        height: 580px;
        .word76 {
          width: 270px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(189, 39, 27, 1);
          font-size: 30px;
          font-family: PingFangSC-Semibold;
          white-space: nowrap;
          line-height: 30px;
          text-align: justify;
          align-self: flex-end;
          margin-right: 438px;
        }
        .word77 {
          width: 495px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(147, 147, 147, 1);
          font-size: 14px;
          white-space: nowrap;
          line-height: 20px;
          text-align: center;
          align-self: center;
          margin-top: 20px;
        }
        .block10 {
          height: 226px;
          border-radius: 20px;
          background-color: rgba(255, 255, 255, 1);
          margin-top: 40px;
          width: 1200px;
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);

          .layer29 {
            width: 966px;
            height: 100px;
            justify-content: space-between;
            margin: 40px 0 0 117px;
            .bd12 {
              height: 105px;
              border-radius: 50%;
              width: 105px;
              .mod25 {
                width: 105px;
                height: 105px;
                background: url(../assets/basic/school01.png) center no-repeat;
              }
            }
            .bd13 {
              position: relative;
              width: 105px;
              height: 105px;
              border-radius: 50%;
              overflow: hidden;
              .outer14 {
                width: 105px;
                height: 105px;
                background: url(../assets/basic/school02.png) center
                  no-repeat;
              }
            }
            .bd14 {
              position: relative;
              width: 105px;
              height: 105px;
              border-radius: 50%;
              overflow: hidden;
              .main33 {
                width: 105px;
                height: 105px;
                background: url(../assets/basic/school03.png) center  no-repeat;
              }
            }
            .bd15 {
              position: relative;
              width: 105px;
              height: 105px;
              border-radius: 50%;
              overflow: hidden;
              .main34 {
                width: 105px;
                height: 105px;
                background: url(../assets/basic/school04.png) center
                  no-repeat;
              }
            }
            .bd16 {
              position: relative;
              width: 105px;
              height: 105px;
              border-radius: 50%;
              overflow: hidden;
              .block11 {
                width: 105px;
                height: 105px;
                background: url(../assets/basic/school05.png) center
                  no-repeat;
              }
            }
          }
          .layer30 {
            width: 946px;
            height: 16px;
            margin: 20px 0 0 135px;
            .word78 {
              width: 64px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(34, 34, 34, 1);
              font-size: 16px;
              white-space: nowrap;
              line-height: 16px;
              text-align: center;
            }
            .word79 {
              width: 96px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(34, 34, 34, 1);
              font-size: 16px;
              white-space: nowrap;
              line-height: 16px;
              text-align: center;
              margin-left: 136px;
            }
            .txt47 {
              width: 96px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(34, 34, 34, 1);
              font-size: 16px;
              white-space: nowrap;
              line-height: 16px;
              text-align: center;
              margin-left: 121px;
            }
            .word80 {
              width: 96px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(34, 34, 34, 1);
              font-size: 16px;
              white-space: nowrap;
              line-height: 16px;
              text-align: center;
              margin-left: 121px;
            }
            .txt48 {
              width: 96px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(34, 34, 34, 1);
              font-size: 16px;
              white-space: nowrap;
              line-height: 16px;
              text-align: center;
              margin-left: 120px;
            }
          }
        }
        .block12 {
          width: 1200px;
          height: 82px;
          margin-top: 60px;
          justify-content: space-between;
          .pic5,.pic6,.img8,.img7,.img6 {
            width: 224px;
            height: 82px;
            &:hover {
              box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
            }
          }
        }
        .block13 {
          width: 1200px;
          height: 82px;
          margin-top: 20px;
          justify-content: space-between;
          .img9,.img10,.pic7,.img11,.img12  {
            width: 224px;
            height: 82px;
            &:hover {
              box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
    }
  }
}
</style>
