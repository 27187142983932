<template>
    <div class="wrapper">
        <div class="page_tips">首页>最新资讯</div>
        <Tips/>
        <div class="article_list">
            <div class="list_type">
                <div class="btn" :class="active == 0?'active':''" @click="active = 0">全部</div>
                <div class="btn" :class="active == 1?'active':''" @click="active = 1">共建专业</div>
                <div class="btn" :class="active == 2?'active':''" @click="active = 2">定向班</div>
                <div class="btn" :class="active == 3?'active':''" @click="active = 3">冠名班</div>
            </div>
            <div class="list">
                <div class="list_item" v-for="(item,index) in  article" :key="index" @click="$router.push('detail')">
                    <div class="txt">
                        <el-tag size="mini" :type="item.type ==0?'':item.type ==1?'warning':'danger'">{{item.type == 0?'共建专业':item.type ==1?'定向班':'冠名班'}}</el-tag>
                        <span class="title">{{item.title}}</span>
                    </div>
                    <div class="time">{{item.time}}</div>
                </div>
                <!-- 分页 -->
                <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage4"
                :page-sizes="[100, 200, 300, 400]"
                :page-size="100"
                layout="total, sizes, prev, pager, next"
                :total="400">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import Tips from '@/components/common/Tips'
export default {
    name:'articleList',
    components: {
        Tips
    },
    data() {
        return {
            active:0,
            article:[
                {
                    type:0,
                    title:'无人机',
                    time:'2021-04-26'
                },
                {
                    type:1,
                    title:'无人机应用技术',
                    time:'2021-04-29'
                },
                {
                    type:2,
                    title:'无人机系统开发',
                    time:'2021-05-06'
                },
                {
                    type:0,
                    title:'无人机',
                    time:'2021-04-26'
                },
                {
                    type:1,
                    title:'无人机应用技术',
                    time:'2021-04-29'
                },
                {
                    type:2,
                    title:'无人机系统开发',
                    time:'2021-05-06'
                },
                {
                    type:0,
                    title:'无人机',
                    time:'2021-04-26'
                },
                {
                    type:1,
                    title:'无人机应用技术',
                    time:'2021-04-29'
                },
                {
                    type:2,
                    title:'无人机系统开发',
                    time:'2021-05-06'
                },
                {
                    type:0,
                    title:'无人机',
                    time:'2021-04-26'
                },
                {
                    type:0,
                    title:'无人机',
                    time:'2021-04-26'
                },
                {
                    type:1,
                    title:'无人机应用技术',
                    time:'2021-04-29'
                },
                {
                    type:2,
                    title:'无人机系统开发',
                    time:'2021-05-06'
                },
                {
                    type:0,
                    title:'无人机',
                    time:'2021-04-26'
                },
                {
                    type:1,
                    title:'无人机应用技术',
                    time:'2021-04-29'
                },
                {
                    type:2,
                    title:'无人机系统开发',
                    time:'2021-05-06'
                },
                {
                    type:0,
                    title:'无人机',
                    time:'2021-04-26'
                },
                {
                    type:1,
                    title:'无人机应用技术',
                    time:'2021-04-29'
                },
                {
                    type:2,
                    title:'无人机系统开发',
                    time:'2021-05-06'
                },
                {
                    type:0,
                    title:'无人机',
                    time:'2021-04-26'
                },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    margin: 20px auto 60px;
    width: 1200px;
    .page_tips {
        color: #AFAFAF;
        font-size: 14px;
        margin-bottom: 20px;
    }
    .article_list {
        margin-top: 40px;
        .list_type {
            display: flex;
            .btn {
                margin-right: 20px;
                width: 120px;
                height: 40px;
                line-height: 40px;
                font-size: 16px;
                color: #222;
                background: #fff;
                border: 1px solid #EAEFF3 ;
                text-align: center;
                border-radius: 20px;
                &.active {
                    background: #222;
                    color: #fff;
                }
            }
        }
        .list {
            margin: 50px auto;
            .list_item {
                display: flex;
                justify-content: space-between;
                margin-bottom: 24px;
                .txt {
                    ::v-deep .el-tag--mini {
                        min-width: 60px;
                        text-align: center;
                    }
                    .title {
                        margin-left: 14px;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}
</style>