<template>
    <div class="tips">{{title}}</div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '最新消息'
        }
    }
}
</script>

<style lang="scss" scoped>
.tips {
    position: relative;
    padding-left: 24px;
    font-size: 24px;
    color: #222;
    &::before {
        content: '';
        position: absolute;
        top: 4px;
        left: 0;
        width: 8px;
        height: 24px;
        background: #B30000;
    }
}
</style>