import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import SchoolLife from '@/views/SchoolLife'
import ArticleList from '@/views/ArticleList'
import ArticleDetail from '@/views/ArticleDetail'
import Product from '@/views/Product'
import Teach from '@/views/Teach'
import Company from '@/views/Company'
import SecondClass from '@/views/SecondClass'
import SchoolServe from '@/views/SchoolServe'
import CompanyRecruit from '@/views/CompanyRecruit'
import JobCenter from '@/views/JobCenter'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/index',
    name: 'Home',
    component: Home
  },
  {
    path: '/schoolLife',
    name: 'SchoolLife',
    component: SchoolLife
  },
  {
    path: '/article',
    name: 'ArticleList',
    component: ArticleList
  },
  {
    path: '/detail',
    name:'ArticleDetail',
    component: ArticleDetail
  },
  {
    path: '/product',
    name:'Product',
    component: Product
  },
  {
    path: '/teach',
    name:'Teach',
    component: Teach
  },
  {
    path: '/company',
    name:'Company',
    component: Company
  },
  {
    path: '/schoolServe',
    name:'SchoolServe',
    component: SchoolServe
  },
  {
    path: '/secondClass',
    name:'SecondClass',
    component: SecondClass
  },
  {
    path: '/companyRecruit',
    name:'CompanyRecruit',
    component: CompanyRecruit
  },
  {
    path: '/jobCenter',
    name:'JobCenter',
    component: JobCenter
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  routes
})


export default router
