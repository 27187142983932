<template>
  <div id="app">
    <Header />
    <router-view/>
    <Counse />
    <Footer />
  </div>
</template>
<script>
import Header from '@/components/common/Header'
import Footer from '@/components/common/Footer'
import Counse from '@/components/common/Counse'

export default {
  name:'app',
  components:{
    Header,
    Footer,
    Counse
  }
}
</script>

<style lang="scss">
@import '@/assets/common.scss';
</style>
