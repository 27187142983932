<template>
  <div class="main1 flex-col">
        <div class="outer2 flex-col">
          <div class="outer3 flex-col">
            <div class="block1 flex-col" :class="show?'active':''">
              <div class="mod1 flex-row">
                <img
                 @click="$router.push('index')"
                  class="pic1"
                  referrerpolicy="no-referrer"
                  :src="show?require('@/assets/basic/homenav_logo_active.png'):require('@/assets/basic/homenav_logo.png')"
                />
                <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" >
                  <el-menu-item index="1"  @click="$router.push('/index')">首页</el-menu-item>
                  <el-submenu index="2">
                    <template slot="title">产品介绍</template>
                    <el-submenu index="2-1">
                      <template slot="title" >第二课堂</template>
                      <el-menu-item index="2-1-1"  @click="$router.push('/secondClass?index=0')">组织活动</el-menu-item>
                      <el-menu-item index="2-1-2"  @click="$router.push('/secondClass?index=1')">组织动态</el-menu-item>
                      <el-menu-item index="2-1-3"  @click="$router.push('/secondClass?index=2')">思政教育</el-menu-item>
                      <el-menu-item index="2-1-4"  @click="$router.push('/secondClass?index=3')">创新创业</el-menu-item>
                      <el-menu-item index="2-1-5"  @click="$router.push('/secondClass?index=4')">志愿公益</el-menu-item>
                      <el-menu-item index="2-1-6"  @click="$router.push('/secondClass?index=5')">奖励申报</el-menu-item>
                    </el-submenu>
                    <el-submenu index="2-2">
                      <template slot="title">企业校招</template>
                      <el-menu-item index="2-2-1">网络大厅</el-menu-item>
                      <el-menu-item index="2-2-2">通信大厅</el-menu-item>
                      <el-menu-item index="2-2-3">失物招领</el-menu-item>
                      <el-menu-item index="2-2-4">校园报修</el-menu-item>
                      <el-menu-item index="2-2-5">跳骚市场</el-menu-item>
                      <el-menu-item index="2-2-6">校园新闻</el-menu-item>
                      <el-menu-item index="2-2-7">跑腿服务</el-menu-item>
                      <el-menu-item index="2-2-8">课程表</el-menu-item>
                      <el-menu-item index="2-2-9">菁星商城</el-menu-item>
                      <el-menu-item index="2-2-10">爆款清单</el-menu-item>
                    </el-submenu>
                    <el-submenu index="2-3">
                      <template slot="title">职教中心</template>
                      <el-menu-item index="2-3-1">网络大厅</el-menu-item>
                      <el-menu-item index="2-3-2">通信大厅</el-menu-item>
                      <el-menu-item index="2-3-3">失物招领</el-menu-item>
                      <el-menu-item index="2-3-4">校园报修</el-menu-item>
                      <el-menu-item index="2-3-5">跳骚市场</el-menu-item>
                      <el-menu-item index="2-3-6">校园新闻</el-menu-item>
                      <el-menu-item index="2-3-7">跑腿服务</el-menu-item>
                      <el-menu-item index="2-3-8">课程表</el-menu-item>
                      <el-menu-item index="2-3-9">菁星商城</el-menu-item>
                      <el-menu-item index="2-3-10">爆款清单</el-menu-item>
                    </el-submenu>
                    <el-submenu index="2-4">
                      <template slot="title">校园服务</template>
                      <el-menu-item index="2-4-1"   @click="$router.push('/schoolServe?index=0')" >网络大厅</el-menu-item>
                      <el-menu-item index="2-4-2"   @click="$router.push('/schoolServe?index=1')" >通信大厅</el-menu-item>
                      <el-menu-item index="2-4-3"   @click="$router.push('/schoolServe?index=2')" >菁星商城</el-menu-item>
                      <el-menu-item index="2-4-4"   @click="$router.push('/schoolServe?index=3')" >爆款清单</el-menu-item>
                      <el-menu-item index="2-4-5"   @click="$router.push('/schoolServe?index=4')" >跑腿服务</el-menu-item>
                      <el-menu-item index="2-4-6"   @click="$router.push('/schoolServe?index=5')" >失物招领</el-menu-item>
                      <el-menu-item index="2-4-7"   @click="$router.push('/schoolServe?index=6')" >校园报修</el-menu-item>
                      <el-menu-item index="2-4-8"   @click="$router.push('/schoolServe?index=7')" >跳骚市场</el-menu-item>
                      <el-menu-item index="2-4-9"   @click="$router.push('/schoolServe?index=8')" >校园新闻</el-menu-item>
                    </el-submenu>
                  </el-submenu>
                  <el-menu-item index="3" @click="$router.push('/schoolLife')">校园生活</el-menu-item>
                  <el-menu-item index="4" @click="$router.push('/teach')">智慧管理</el-menu-item>
                  <el-menu-item index="5" @click="$router.push('/company')">企业服务</el-menu-item>
                  <el-menu-item index="6" @click="$router.push('/about')">关于我们</el-menu-item>
                </el-menu>
                <div class="layer1 flex-col">
                  <div class="block2 flex-row">
                    <img
                      class="label1"
                      referrerpolicy="no-referrer"
                      :src="show?require('@/assets/basic/nav_company.png'):require('@/assets/basic/homenav_company.png')"
                    />
                    <span class="info1">企业入驻</span>
                  </div>
                </div>
                <div class="layer2 flex-col">
                  <div class="group1 flex-row">
                    <img
                      class="icon1"
                      referrerpolicy="no-referrer"
                      :src="show?require('@/assets/basic/nav_school.png'):require('@/assets/basic/homenav_school.png')"
                    />
                    <span class="info2">院校入驻</span>
                  </div>
                </div>
                <div class="layer3 flex-col">
                  <div class="bd1 flex-row" @click="$router.push('product')">
                    <img
                      class="label2"
                      referrerpolicy="no-referrer"
                      :src="show?require('@/assets/basic/nav_download.png'):require('@/assets/basic/nav_download_active.png')"
                    />
                    <span class="word3">APP下载</span>
                  </div>
                </div>
              </div>
            </div>
            <div style="height:80px" v-if="show">

            </div>
            <div style="height:500px;width:100%" v-if="showSwiper">
              <swiper class="swiper" :options="swiperOption">
                  <swiper-slide>
                    <img :src="require('@/assets/basic/banner01.png')" alt="">
                  </swiper-slide>
                  <swiper-slide>
                    <img :src="require('@/assets/basic/banner01.png')" alt="">
                  </swiper-slide>
                  <swiper-slide>
                    <img :src="require('@/assets/basic/banner01.png')" alt="">
                  </swiper-slide>
                  <swiper-slide>
                    <img :src="require('@/assets/basic/banner01.png')" alt="">
                  </swiper-slide>
                  <!-- <div class="swiper-pagination" slot="pagination"></div>
                  <div class="swiper-button-prev" slot="button-prev"></div>
                  <div class="swiper-button-next" slot="button-next"></div> -->
                </swiper>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import { mapState,mapActions } from 'vuex'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
    name:'headernav',
    data() {
        return {
            scrollHeight:0,
            activeIndex:'1',
            show:false,
            swiperOption: { //同swiper
              slidesPerView: 1,
              spaceBetween: 30,
              loop: true,
              pagination: {
                el: '.swiper-pagination',
                clickable: true
              },
              autoplay:true,
              // navigation: {
              //   nextEl: '.swiper-button-next',
              //   prevEl: '.swiper-button-prev'
              // }
            }
        }
    },
    watch:{
        scrollHeight() {
            if (this.scrollHeight >= 80) {
              this.show = true
            }else {
              if (this.$route.path == 'index') {
                this.show = false
              }else {
                this.show = true
              }
            }
        },
        $route:{
          handler(path) {
            console.log(path)
            if (path.name != 'Home') {
              this.handleSwiper(false)
              this.show = true
            }else {
              if(this.scrollHeight <80 && path.name == 'Home') this.show = false
              this.handleSwiper(true)
            }
          },
          immediate:true
        }
    },
    computed:{
      ...mapState(['showSwiper'])
    },
    methods:{
      ...mapActions(['changeSwiper']),
      handleScroll() {
          this.scrollHeight = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)
      },
      handleSwiper(params) {
        this.changeSwiper(params)
      }
    },
    components:{
      Swiper,
      SwiperSlide
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll, true)
      if (this.$route.path == '/index' || this.$route.path == '/') {
        this.handleSwiper(true)
      }else{
        this.handleSwiper(false)
      }
    }
}
</script>

<style lang="scss" scoped>
.active {
    background-color: #fff!important;
    position: fixed;
    top: 0;
    z-index: 999;
    .txt1 {
        color: #222!important;
    }
    .layer1 {
        border: 0.5px solid #AFAFAF!important;
        .info1 {
            color: #bd271b!important;
        }
    }
    .layer2 {
        border: 0.5px solid #AFAFAF!important;
        .info2 {
            color: #bd271b!important;
        }
    }
    .layer3 {
        border: 0.5px solid #AFAFAF!important;
        background-color: #bd271b!important;
        .word3 {
            color: #fff!important;
        }
    }
    ::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title,.el-menu--horizontal>.el-menu-item {
      color: #222!important;
    }
    ::v-deep .el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
      color: #bd271b!important;
    }
}
.main1 {
      height: auto;
      background-color: rgba(216, 216, 216, 1);
      width: 100%;
      .outer2 {
        height: auto;
        width: 100%;
        justify-content: flex-start;
        position: relative;
        .outer3 {
          width: 100%;
          height: auto;
          .block1 {
            z-index: 1069;
            height: 80px;
            background-color: rgba(0, 0, 0, 0.1);
            box-shadow: inset 0px -1px 0px 0px rgba(255, 255, 255, 0.3);
            width: 100%;
            .mod1 {
              z-index: auto;
              width: 1200px;
              height: 60px;
              margin: 10px 0 0 360px;
              .pic1 {
                z-index: 1095;
                width: 170px;
                height: 60px;
              }
              .txt1 {
                    z-index: 497;
                    width: auto;
                    display: block;
                    overflow-wrap: break-word;
                    color: #fff;
                    font-size: 16px;
                    white-space: nowrap;
                    line-height: 16px;
                    text-align: center;
                    padding: 22px 20px;
                    margin: 0 2px;
                    &:hover {
                        background-color: rgba(255, 255, 255, 0.8);
                        color: #222;
                    }
              }
              
              .layer1 {
                z-index: 1079;
                height: 44px;
                border-radius: 6px;
                border-width: 0.5px;
                border: 0.5px solid rgba(255, 255, 255, 1);
                width: 118px;
                justify-content: center;
                align-items: center;
                margin: 8px 0 0 0px;
                .block2 {
                  z-index: auto;
                  width: 90px;
                  height: 16px;
                  justify-content: space-between;
                  .label1 {
                    z-index: 1080;
                    width: 16px;
                    height: 16px;
                  }
                  .info1 {
                    z-index: 1086;
                    width: 64px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(255, 255, 255, 1);
                    font-size: 16px;
                    white-space: nowrap;
                    line-height: 16px;
                    text-align: center;
                  }
                }
              }
              .layer2 {
                z-index: 1071;
                height: 44px;
                border-radius: 6px;
                border-width: 0.5px;
                border: 0.5px solid rgba(255, 255, 255, 1);
                width: 118px;
                justify-content: center;
                align-items: center;
                margin: 8px 0 0 20px;
                .group1 {
                  z-index: auto;
                  width: 90px;
                  height: 16px;
                  justify-content: space-between;
                  .icon1 {
                    z-index: 1073;
                    width: 16px;
                    height: 16px;
                  }
                  .info2 {
                    z-index: 1072;
                    width: 64px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(255, 255, 255, 1);
                    font-size: 16px;
                    white-space: nowrap;
                    line-height: 16px;
                    text-align: center;
                  }
                }
              }
              .layer3 {
                z-index: 1088;
                height: 44px;
                border-radius: 6px;
                background-color: rgba(255, 255, 255, 1);
                width: 118px;
                justify-content: center;
                align-items: center;
                margin: 8px 0 0 20px;
                .bd1 {
                  z-index: auto;
                  width: 90px;
                  height: 16px;
                  justify-content: space-between;
                  .label2 {
                    z-index: 1089;
                    width: 16px;
                    height: 16px;
                  }
                  .word3 {
                    z-index: 1094;
                    width: 64px;
                    display: block;
                    overflow-wrap: break-word;
                    color: rgba(189, 39, 27, 1);
                    font-size: 16px;
                    white-space: nowrap;
                    line-height: 16px;
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }
      ::v-deep .el-menu {
        background-color: rgba(0, 0, 0,0);
      }
      ::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title,.el-menu--horizontal>.el-menu-item {
        color: #fff;
        font-weight: 600;
      }
      ::v-deep .el-menu.el-menu--horizontal{
        border-bottom: 0;
      }
      ::v-deep .el-menu--horizontal>.el-menu-item{
        height: 70px;
        line-height: 70px;
      }
      ::v-deep .el-menu--horizontal>.el-submenu .el-submenu__title{
        height: 70px;
        line-height: 70px;
      }
      ::v-deep .el-menu--horizontal>.el-menu-item.is-active{
            border-bottom: 4px solid #ffffff;
      }
      ::v-deep .el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, .el-menu--horizontal>.el-submenu .el-submenu__title:hover {
        background-color: rgba(0, 0, 0,0);
      }
      ::v-deep .el-submenu__title:hover {
        background-color: rgba(0, 0, 0,0);
      }
      ::v-deep .el-menu--horizontal .el-menu-item:not(.is-disabled):focus, .el-menu--horizontal .el-menu-item:not(.is-disabled):hover{
        color: #fff;
      }
      ::v-deep .el-submenu__title:hover,.el-submenu__title:focus, .el-submenu__title:hover,.el-menu--horizontal>.el-menu-item:not(.is-disabled):focus, .el-menu--horizontal>.el-menu-item:not(.is-disabled):hover, .el-menu--horizontal>.el-submenu .el-submenu__title:hover{
        background-color: rgba(0, 0, 0,0)!important;
      }
    }
  .swiper {
      margin-top: -80px;
    height: 740px;
    width: 100%;
}
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    background-color:#ffffff;
  }
.el-menu-demo {
      min-width: 640px;
    display: flex;
    justify-content: center;
}
</style>