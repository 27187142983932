<template>
    <div class="wrapper">
        <div class="app_header">
            <span>校园服务</span>
            <div class="cont">
                校友圈的简介校友圈的简介校友圈的简介校友圈的简介校友圈的简介校友圈的简介校友圈的简介校友圈的简介校友圈的简介校友圈的简介校友圈的简介校友圈的简介校友圈的简介校友圈的简介校友圈的简介校友圈的简介校友圈的简介校友圈的简介
            </div>
            <div class="logo"></div>
        </div>
        <div class="content">
            <div class="nav">
                <div class="nav_item" :class="activeIndex == 0?'active':''" @click="activeIndex = 0">组织活动</div>
                <div class="nav_item" :class="activeIndex == 1?'active':''" @click="activeIndex = 1">创新创业</div>
                <div class="nav_item" :class="activeIndex == 2?'active':''" @click="activeIndex = 2">师资建设</div>
                <div class="nav_item" :class="activeIndex == 3?'active':''" @click="activeIndex = 3">校园招聘</div>
                <div class="nav_item" :class="activeIndex == 4?'active':''" @click="activeIndex = 4">学科竞赛</div>
                <div class="nav_item" :class="activeIndex == 5?'active':''" @click="activeIndex = 5">技能大赛</div>
            </div>
            <div class="cont_body">
                <div class="body_item" v-show="activeIndex == 0">
                    <div class="item_h">
                         <div class="left">
                             <span>组织活动</span>
                             <div class="intro">组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介</div>
                            <div class="btn">
                                <i></i>
                                <span>立即下载</span>
                            </div>
                         </div>
                         <img class="right" :src="require('@/assets/basic/app_cont01.png')" alt="">
                    </div>
                    <div class="content">
                        sdfsdf
                    </div>
                </div>
                <div class="body_item" v-show="activeIndex == 1">
                    <div class="item_h">
                         <div class="left">
                             <span>创新创业</span>
                             <div class="intro">组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介</div>
                            <div class="btn">
                                <i></i>
                                <span>立即下载</span>
                            </div>
                         </div>
                         <img class="right" :src="require('@/assets/basic/app_cont01.png')" alt="">
                    </div>
                    <div class="content">
                        sdfsdf
                    </div>
                </div>
                <div class="body_item" v-show="activeIndex == 2">
                    <div class="item_h">
                         <div class="left">
                             <span>师资建设</span>
                             <div class="intro">组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介</div>
                            <div class="btn">
                                <i></i>
                                <span>立即下载</span>
                            </div>
                         </div>
                         <img class="right" :src="require('@/assets/basic/app_cont01.png')" alt="">
                    </div>
                    <div class="content">
                        sdfsdf
                    </div>
                </div>
                <div class="body_item" v-show="activeIndex == 3">
                    <div class="item_h">
                         <div class="left">
                             <span>校园招聘</span>
                             <div class="intro">组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介</div>
                            <div class="btn">
                                <i></i>
                                <span>立即下载</span>
                            </div>
                         </div>
                         <img class="right" :src="require('@/assets/basic/app_cont01.png')" alt="">
                    </div>
                    <div class="content">
                        sdfsdf
                    </div>
                </div>
                <div class="body_item" v-show="activeIndex == 4">
                    <div class="item_h">
                         <div class="left">
                             <span>学科竞赛</span>
                             <div class="intro">组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介</div>
                            <div class="btn">
                                <i></i>
                                <span>立即下载</span>
                            </div>
                         </div>
                         <img class="right" :src="require('@/assets/basic/app_cont01.png')" alt="">
                    </div>
                    <div class="content">
                        sdfsdf
                    </div>
                </div>
                <div class="body_item" v-show="activeIndex == 5">
                    <div class="item_h">
                         <div class="left">
                             <span>技能大赛</span>
                             <div class="intro">组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介组织活动简介</div>
                            <div class="btn">
                                <i></i>
                                <span>立即下载</span>
                            </div>
                         </div>
                         <img class="right" :src="require('@/assets/basic/app_cont01.png')" alt="">
                    </div>
                    <div class="content">
                        sdfsdf
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'application',
    data() {
        return {
            activeIndex:0
        }
    },
    watch:{
        $route() {
            console.log(this.$route.query.index)
            this.activeIndex = this.$route.query.index
        }
    }
}
</script>

<style lang="scss" scoped>
.wrapper {
    width: 1200px;
    margin: 20px auto 60px;
    .app_header {
        position: relative;
        background: url('../assets/basic/app_h.png') center no-repeat;
        border-radius: 10px;
        padding: 40px;
        >span {
            font-size: 24px;
            font-weight: 600;
            color: #fff;
        }
        .cont {
            margin-top: 20px;
            font-size: 16px;
            width: 700px;
            line-height: 24px;
            color: #fff;
        }
        .logo {
            position: absolute;
            width:120px;
            height: 120px;
            right: 40px;
            top: 50%;
            transform: translateY(-50%);
            background: #fff;
            border-radius: 20px;
        }
    }
    .content {
        margin-top: 40px;
        display: flex;
        .nav {
            width: 150px;
            margin-right: 40px;
            .nav_item {
                width: 150px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                font-size: 18px;
                color: #222;
                border-radius: 25px;
                border: 1px solid #EAEFF3;
                margin: 20px 0;
                &.active {
                    background: #222;
                    color: #fff;
                }
            }
        }
        .cont_body {
            .body_item {
                .item_h {
                    position: relative;
                    width: 1010px;
                    height: 350px;
                    background: #FAFAFA;
                    border-radius: 10px;
                    padding: 40px;
                    .left {
                        span {
                            font-size: 24px;
                            font-weight: 600;
                        }
                        .intro {
                            margin: 20px 0 100px;
                            width: 490px;
                            font-size: 14px;
                            color: #939393;
                        }
                        .btn {
                            width: 128px;
                            display: flex;
                            align-items: center;
                            padding: 14px 20px;
                            background: rgba(189, 39, 27, 0.06);
                            border-radius: 6px;
                            border: 1px solid rgba(189, 39, 27, 0.1);
                            > i {
                                display: block;
                                width: 16px;
                                height: 16px;
                                margin-right: 5px;
                                background: url("../assets/basic/homenav_download.png") center no-repeat;
                            }
                            > span {
                                color: #B30000;
                                font-size: 16px;
                            }
                            }
                    }
                    .right {
                        position: absolute;
                        bottom: 0;
                        right: 40px;
                    }
                }
            }
        }
    }
}
</style>