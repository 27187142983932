<template>
  <transition name="el-fade-in">
    <div class="zixun" v-show="show">
      <div class="counse">
        <div class="cont">
          <div class="counse_item">
            <img :src="require('@/assets/basic/zixun.png')" alt="" />
            <span>咨询</span>
          </div>
          <div class="counse_item">
            <span class="ic"></span>
            <span>APP下载</span>
          </div>
        </div>
      </div>
      <div class="top" @click="backTop">
        <img :src="require('@/assets/basic/to_top.png')" alt="" />
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Counse",
  data() {
    return {
      scrollHeight: 0,
      show: false,
    };
  },
  watch: {
    scrollHeight() {
      if (this.scrollHeight >= 650) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
  methods: {
    handleScroll() {
      this.scrollHeight = Math.floor(
        document.body.scrollTop ||
          document.documentElement.scrollTop ||
          window.pageYOffset
      );
    },
    backTop() {
      let top  = document.documentElement.scrollTop || document.body.scrollTop
      // 滚动
      const timeTop = setInterval(()=>{
        document.body.scrollTop = document.documentElement.scrollTop = top-=50
        if (top<=0) {
          clearInterval(timeTop)
        }
      },10)
    }
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
};
</script>

<style lang="scss" scoped>
.zixun {
  position: fixed;
  bottom: 160px;
  right: 35px;
  width: 70px;
  font-size: 14px;
  color: #bd271b;
  z-index: 9999;
  .counse {
    overflow: hidden;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.1);
    .cont {
      .counse_item {
        text-align: center;
        > img {
          display: block;
          margin: 10px auto;
          width: 16px;
          height: 16px;
        }
        .ic {
          display: block;
          margin: 0 auto 5px;
          width: 16px;
          height: 16px;
          background: url(../../assets/basic/nav_download_active.png) no-repeat center;
        }
        margin: 10px 10px 0;
        padding: 10px;
        border-radius: 6px;
        &:first-child {
          background-color: #bd271b;
          color: #fff;
        }
        &:hover {
          background-color: #bd271b;
          color: #fff;
          .ic {
            background: url(../../assets/basic/nav_download.png) no-repeat center;
          }
        }
      }
    }
  }
  .top {
    margin-top: 10px;
    width: 70px;
    padding: 10px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.1);
    > img {
      display: block;
      margin: 10px auto;
    }
  }
}
</style>