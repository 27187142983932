<!--
 * @Auhtor: 文锋
 * @Date: 2021-08-07 07:37:40
 * @LastEditors: Do not edit
 * @LastEditTime: 2021-08-08 17:47:22
 * @FilePath: \anqin\src\views\SchoolLife.vue
-->
<template>
<div>
<div class="wrapper">
      <Conterheader />
      <div class="nav">
        <div class="nav-item">
          <span>大国工匠</span>
          <div>
            <div class="tips">Web</div>
            <span>></span>
          </div>
        </div>
        <div class="nav-item">
          <span>箐星赛事</span>
          <div>
            <div class="tips">Web</div>
            <span>></span>
          </div>
        </div>
        <div class="nav-item">
          <span>CEO运营实战平台备份</span>
          <div>
            <div class="tips">Web</div>
            <span>></span>
          </div>
        </div>
      </div>
      <!-- 安青前程 -->
      <div class="future">
        <p>安青前程为您提供</p>
        <div class="future_cont">
          <div class="cont_nav">
            <div
              class="nav_item"
              :class="typeIndex == 0 ? 'active' : ''"
              @click="typeIndex = 0"
            >
              <i></i>
              <span>大国工匠</span>
            </div>
            <div
              class="nav_item"
              :class="typeIndex == 1 ? 'active' : ''"
              @click="typeIndex = 1"
            >
              <i></i>
              <span>第二课堂</span>
            </div>
            <div
              class="nav_item"
              :class="typeIndex == 2 ? 'active' : ''"
              @click="typeIndex = 2"
            >
              <i></i>
              <span>企业校招</span>
            </div>
            <div
              class="nav_item"
              :class="typeIndex == 3 ? 'active' : ''"
              @click="typeIndex = 3"
            >
              <i></i>
              <span>职教中心</span>
            </div>
          </div>
          <div class="cont_body">
            <div class="body_item" v-show="typeIndex == 0">
              <div class="txt">
                <span>大国工匠</span>
                <p>
                  依托行业技术优势，聚焦生态企业用人需求，立足教材和学习资源开发，协助试点院校实施证书培训，助力新一代信息技术产业人才培养。
                </p>
              </div>
              <div class="icon">
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
              </div>
            </div>
            <div class="body_item" v-show="typeIndex == 1">
              <div class="txt">
                <span>第二课堂</span>
                <p>
                  依托行业技术优势，聚焦生态企业用人需求，立足教材和学习资源开发，协助试点院校实施证书培训，助力新一代信息技术产业人才培养。
                </p>
              </div>
              <div class="icon">
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
              </div>
            </div>
            <div class="body_item" v-show="typeIndex == 2">
              <div class="txt">
                <span>企业校招</span>
                <p>
                  依托行业技术优势，聚焦生态企业用人需求，立足教材和学习资源开发，协助试点院校实施证书培训，助力新一代信息技术产业人才培养。
                </p>
              </div>
              <div class="icon">
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
              </div>
            </div>
            <div class="body_item" v-show="typeIndex == 3">
              <div class="txt">
                <span>职教中心</span>
                <p>
                  依托行业技术优势，聚焦生态企业用人需求，立足教材和学习资源开发，协助试点院校实施证书培训，助力新一代信息技术产业人才培养。
                </p>
              </div>
              <div class="icon">
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
                <div class="item">
                  <i></i>
                  <span>定向培养</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 专属成绩单 -->
      <div class="score">
        <p>第二课堂专属成绩单</p>
        <p class="intro">
          将您在校期间参与的活动组织经历、思政教育、创新创业、校园文化等活动和取得的除课业成绩之外的奖励和荣誉记录下来，进行分类管理。在毕业时，为您提供一份“第二课堂成绩单”，全方位、多角度反映你在学校的发展情况
        </p>
        <img :src="require('@/assets/basic/school_intro.png')" alt="" />
      </div>
      <div class="match">
        <span>创新创业/技能大赛</span>
        <div class="intro">
          校友圈的简介校友圈的简介校友圈的简介校友圈的简介校友圈的简介校友圈的简介校友圈的简介校友圈的简介校友圈的简介校友圈的简介校友圈的简介校友圈的简介校友圈的简介校友圈的简介校友圈的简介校友圈的简介校友圈的简介校友圈的简介
        </div>
        <div class="btn">
          <i></i>
          <span>前往查看</span>
        </div>
      </div>
      <!-- 应用场景 -->
      <div class="scene">
        <p>应用场景</p>
        <div class="scene_body">
          <div class="body_item">
            <img :src="require('@/assets/basic/school_icon01.png')" alt="" />
            <span>智慧教学</span>
            <p>
              依托行业技术优势，聚焦生态企业用人需求，立足教材和学习资源开发，协助试点院校实施证书培训，助力新一代信息技术产业人才培养。
            </p>
          </div>
          <div class="body_item">
            <img :src="require('@/assets/basic/school_icon02.png')" alt="" />
            <span>精准校招</span>
            <p>
              依托行业技术优势，聚焦生态企业用人需求，立足教材和学习资源开发，协助试点院校实施证书培训，助力新一代信息技术产业人才培养。
            </p>
          </div>
          <div class="body_item">
            <img :src="require('@/assets/basic/school_icon03.png')" alt="" />
            <span>技能提升</span>
            <p>
              赛项围绕新一代信息技术的发展趋势和产业中的岗位技能而设计，通过开展竞赛深化产教融合，为人工智能、移动应用开发、区块链等产业培养技能人才。
            </p>
          </div>
          <div class="body_item">
            <img :src="require('@/assets/basic/school_icon04.png')" alt="" />
            <span>创新创业/技能大赛</span>
            <p>
              赛项围绕新一代信息技术的发展趋势和产业中的岗位技能而设计，通过开展竞赛深化产教融合，为人工智能、移动应用开发、区块链等产业培养技能人才。
            </p>
          </div>
        </div>
      </div>
      <!-- 成绩单 -->
      <!-- 校招直通车 -->
      <div class="school_job">
        <div class="tit">校招直通车</div>
        <div class="layer6">
          <div
            class="layer8"
            :class="activeIndex == 0 ? 'active' : ''"
            @click="activeIndex = 0"
          >
            <span class="txt23">推荐名企</span>
          </div>
          <div
            class="layer8"
            :class="activeIndex == 1 ? 'active' : ''"
            @click="activeIndex = 1"
          >
            <span class="txt23">推荐岗位</span>
          </div>
        </div>
        <div class="item" v-show="activeIndex == 0">
          <div class="item-box" v-for="(item, index) in companyList" :key="index">
            <span>在找职位{{ item.num }}个</span>
            <div class="logo">
              <img :src="item.logoUrl" alt="" />
            </div>
            <div>
              <p>{{ item.title }}</p>
              <p class="con">{{ item.content }}</p>
              <div class="info">查看详情</div>
            </div>
          </div>
        </div>
        <div class="item" v-show="activeIndex == 1">
          <div class="item-boxSty2" v-for="index in 8" :key="index">
            <span>市场策划</span>
            <div class="money">10k-15k*13薪</div>
            <div class="exe">经验1-3年 / 大专</div>
            <div class="tag">
              <span>扁平管理</span>
              <span>扁平管理</span>
              <span>扁平管理</span>
            </div>

            <div class="company">
              <div class="logo">
                <img :src="require('@/assets/basic/company01.png')" alt="" />
                <span>腾讯</span>
              </div>
              <div class="info">查看详情</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 核心功能 -->
      <div class="core">
        <p>更多核心功能尽在安青前程</p>
        <div class="core_body">
          <div class="core_item">
            <div class="icon">
              <i></i>
              <span>通信大厅</span>
            </div>
            <div class="cont">
              应用概述应用概述应用概述应用概述应用概述应用概述应用概述
            </div>
          </div>
          <div class="core_item">
            <div class="icon">
              <i></i>
              <span>网络大厅</span>
            </div>
            <div class="cont">
              应用概述应用概述应用概述应用概述应用概述应用概述应用概述
            </div>
          </div>
          <div class="core_item">
            <div class="icon">
              <i></i>
              <span>校园兼职</span>
            </div>
            <div class="cont">
              应用概述应用概述应用概述应用概述应用概述应用概述应用概述
            </div>
          </div>
          <div class="core_item">
            <div class="icon">
              <i></i>
              <span>跳蚤市场</span>
            </div>
            <div class="cont">
              应用概述应用概述应用概述应用概述应用概述应用概述应用概述
            </div>
          </div>
        </div>
      </div>
    </div>
    <Logo />
</div>
</template>

<script>
import Conterheader from "@/components/common/ConterHeader";
import Logo from "@/components/common/Logo"
export default {
  name: "schoolLife",
  components: {
    Conterheader,
    Logo
  },
  data() {
    return {
      typeIndex: 0,
      activeIndex: 0,
      companyList: [
        {
          title: "腾讯",
          num: 253,
          logoUrl: require("@/assets/basic/company01.png"),
          content:
            "公司概述公司概述公司概述公司概述公司概述公司概述公司概述公司概述",
        },
        {
          title: "小米",
          num: 13,
          logoUrl: require("@/assets/basic/company02.png"),
          content:
            "公司概述公司概述公司概述公司概述公司概述公司概述公司概述公司概述",
        },
        {
          title: "华为",
          num: 26,
          logoUrl: require("@/assets/basic/company03.png"),
          content:
            "公司概述公司概述公司概述公司概述公司概述公司概述公司概述公司概述",
        },
        {
          title: "OPPO",
          num: 135,
          logoUrl: require("@/assets/basic/company04.png"),
          content:
            "公司概述公司概述公司概述公司概述公司概述公司概述公司概述公司概述",
        },
        {
          title: "字节跳动",
          num: 19,
          logoUrl: require("@/assets/basic/company05.png"),
          content:
            "公司概述公司概述公司概述公司概述公司概述公司概述公司概述公司概述",
        },
        {
          title: "阿里巴巴",
          num: 3,
          logoUrl: require("@/assets/basic/company06.png"),
          content:
            "公司概述公司概述公司概述公司概述公司概述公司概述公司概述公司概述",
        },
        {
          title: "京东",
          num: 139,
          logoUrl: require("@/assets/basic/company07.png"),
          content:
            "公司概述公司概述公司概述公司概述公司概述公司概述公司概述公司概述",
        },
        {
          title: "拼多多",
          num: 38,
          logoUrl: require("@/assets/basic/company08.png"),
          content:
            "公司概述公司概述公司概述公司概述公司概述公司概述公司概述公司概述",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  width: 1200px;
  margin: 20px auto;
  .nav {
    margin: 20px auto 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    .nav-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      width: 387px;
      height: 50px;
      border-radius: 10px;
      background: #ccc;
      > span {
        font-size: 16px;
        color: #fff;
      }
      > div {
        display: flex;
        align-items: center;
        .tips {
          margin-right: 10px;
          padding: 6px;
          font-size: 14px;
          color: #fff;
          border: 1px solid #fff;
          border-radius: 6px;
        }
        > span {
          color: #fff;
        }
      }
      &:nth-child(1) {
        background: url("../assets/basic/school_nav01.png") center no-repeat;
      }
      &:nth-child(2) {
        background: url("../assets/basic/school_nav02.png") center no-repeat;
      }
      &:nth-child(3) {
        background: url("../assets/basic/school_nav03.png") center no-repeat;
      }
    }
  }
  .future {
    > p {
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
    }
    .future_cont {
      display: flex;
      margin: 60px auto 0;
      .cont_nav {
        position: relative;
        width: 300px;
        height: 310px;
        text-align: center;
        background: url("../assets/basic/school_icon_bg.png") center no-repeat;
        .nav_item {
          position: absolute;
          top: 0%;
          left: 50%;
          transform: translateX(-50%);
          width: 150px;
          height: 120px;
          &.active {
            background: url("../assets/basic/school_icon_active.png") center no-repeat;
              span {
              color: #bd271b;
              font-weight: 600;
            }
          }
          > i {
            margin: 0 auto 5px;
            display: block;
            width: 36px;
            height: 36px;
            background: url("../assets/basic/school_icon1.png");
          }
          font-size: 14px;
          &:nth-child(2) {
            position: absolute;
            top: 25%;
            left: 25%;
            > i {
              width: 36px;
              height: 34px;
              background: url("../assets/basic/school_icon2.png");
            }
          }
          &:nth-child(3) {
            position: absolute;
            top: 25%;
            left: 75%;
            > i {
              width: 36px;
              height: 36px;
              background: url("../assets/basic/school_icon3.png");
            }
          }
          &:nth-child(4) {
            position: absolute;
            top: 50%;
            right: 25%;
            > i {
              width: 36px;
              height: 30px;
              background: url("../assets/basic/school_icon4.png");
            }
          }
        }
      }
      .cont_body {
        margin-left: 70px;
        width: 700px;
        .body_item {
          .txt {
            position: relative;
            height: 144px;
            padding: 30px;
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
            &::before {
              content: "";
              display: block;
              position: absolute;
              top: 0;
              left: 0;
              width: 4px;
              height: 144px;
              background: #bd271b;
            }
            > span {
              font-size: 24px;
              font-weight: 600;
            }
            > p {
              font-size: 14px;
              color: #939393;
            }
          }
          .icon {
            display: flex;
            flex-wrap: wrap;
            width: 660px;
            background: #f5f9fa;
            margin: 0 auto;
            padding: 10px 30px;
            .item {
              margin: 12px 0;
              width: 25%;
              display: flex;
              color: #4e4e4e;
              font-size: 14px;
              > i {
                display: block;
                width: 24px;
                height: 24px;
                margin-right: 10px;
                background: url("../assets/basic/icon01.png");
              }
              &:nth-child(2) {
                > i {
                  background: url("../assets/basic/icon02.png");
                }
              }
              &:nth-child(3) {
                > i {
                  height: 22px;
                  background: url("../assets/basic/icon03.png");
                }
              }
              &:nth-child(4) {
                > i {
                  height: 20px;
                  background: url("../assets/basic/icon04.png");
                }
              }
              &:nth-child(5) {
                > i {
                  background: url("../assets/basic/icon05.png");
                }
              }
              &:nth-child(6) {
                > i {
                  background: url("../assets/basic/icon06.png");
                }
              }
              &:nth-child(7) {
                > i {
                  background: url("../assets/basic/icon07.png");
                }
              }
              &:nth-child(8) {
                > i {
                  background: url("../assets/basic/icon07.png");
                }
              }
            }
          }
        }
      }
    }
  }
  .score {
    > p {
      margin: 60px 0 40px;
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
    }
    .intro {
      width: 600px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #939393;
      line-height: 20px;
    }
  }
  .match {
    margin: 60px auto;
    width: 1200px;
    height: 280px;
    padding: 40px;
    background: url("../assets/basic/school_match.png") center no-repeat;
    > span {
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
    .intro {
      margin: 20px 0 50px 0;
      width: 600px;
      font-size: 14px;
      color: #fff;
      line-height: 20px;
    }
    .btn {
      width: 128px;
      display: flex;
      align-items: center;
      padding: 14px 20px;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 6px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      > i {
        display: block;
        width: 16px;
        height: 16px;
        margin-right: 5px;
        background: url("../assets/basic/icon_watch.png") center no-repeat;
      }
      > span {
        color: #fff;
        font-size: 16px;
      }
    }
  }
  .scene {
    margin: 60px auto 40px;
    > p {
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
    }
    .scene_body {
      display: flex;
      justify-content: space-between;
      .body_item {
        display: flex;
        flex-direction: column;
        width: 250px;
        padding: 20px;
        border: 1px solid #eaeff3;
        border-radius: 10px;
        > img {
          width: 70px;
          height: 70px;
        }
        > span {
          margin: 20px 0;
          font-size: 20px;
          font-weight: 600;
        }
        > p {
          margin: 0;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #939393;
          line-height: 20px;
        }
      }
    }
  }
  .school_job {
    height: 924px;
    margin-top: 61px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    .tit {
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
    }
    .layer6 {
      height: 50px;
      display: flex;
      margin: 40px 0;
      .layer8 {
        margin-right: 40px;
        height: 50px;
        border-radius: 25px;
        border-width: 1px;
        border: 1px solid rgba(234, 239, 243, 1);
        background-color: rgba(255, 255, 255, 1);
        width: 150px;
        justify-content: center;
        align-items: center;
        text-align: center;
        .txt23 {
          width: 100%;
          display: block;
          overflow-wrap: break-word;
          color: rgba(34, 34, 34, 1);
          font-size: 18px;
          white-space: nowrap;
          line-height: 50px;
        }
        &.active {
          background-color: rgba(34, 34, 34, 1);
          .txt23 {
            color: rgba(255, 255, 255, 1);
          }
        }
      }
    }
    .item {
      width: 1180px;
      margin: 20px auto 40px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .item-box {
        position: relative;
        width: 250px;
        height: 302px;
        margin: 20px;
        padding: 0 20px 20px;
        border-radius: 10px;
        background-color: #fff;
        transition: 0.5s all ease;
        border-radius: 10px;
        border: 1px solid #eaeff3;
        > span {
          position: absolute;
          left: 0;
          top: 0;
          height: 32px;
          line-height: 32px;
          padding: 0 10px;
          border-radius: 10px 0 16px;
          color: #8e8e8e;
          background-color: #eee;
          z-index: 10;
        }
        .logo {
          position: relative;
          width: 100%;
          height: 120px;
          img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            margin: 0 auto;
            height: auto;
          }
        }
        > div {
          p:first-child {
            color: #222222;
            font-weight: 600;
            font-size: 18px;
            text-align: center;
          }
          .con {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
          .info {
            margin-top: 20px;
            width: 210px;
            height: 44px;
            line-height: 44px;
            color: #222;
            text-align: center;
            background-color: #fff;
            border-radius: 6px;
            border: 1px solid #eaeff3;
            &:hover {
              color: #fff;
              background-color: #bd271b;
            }
          }
        }
        &:hover {
          transform: translateY(-4px);
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
        }
      }
      .item-boxSty2 {
        position: relative;
        width: 250px;
        height: 302px;
        margin: 20px;
        padding: 20px;
        border-radius: 10px;
        background-color: #fff;
        transition: 0.5s all ease;
        border-radius: 10px;
        border: 1px solid #eaeff3;
        font-size: 18px;
        > span {
          font-weight: 600;
        }
        .money {
          color: #bd271b;
          margin: 14px 0;
        }
        .tag {
          margin: 10px auto 20px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          > span {
            font-size: 12px;
            padding: 3px 5px;
            border-radius: 4px;
            margin: 0 2px;
            border: 1px solid #eaeff3;
          }
        }
        .exe {
          font-size: 14px;
          color: #939393;
        }
        .company {
          .logo {
            display: flex;
            align-items: center;
            img {
              width: 40px;
              height: 40px;
              border-radius: 4px;
              margin-right: 20px;
              border: 1px solid #eaeff3;
            }
            > span {
              font-weight: 600;
            }
          }
          .info {
            margin-top: 20px;
            width: 210px;
            height: 44px;
            line-height: 44px;
            color: #222;
            text-align: center;
            background-color: #fff;
            border-radius: 6px;
            border: 1px solid #eaeff3;
            &:hover {
              color: #fff;
              background-color: #bd271b;
            }
          }
        }
        &:hover {
          transform: translateY(-4px);
          box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
        }
      }
    }
  }
  .core {
    > p {
      margin: 0;
      font-size: 30px;
      font-weight: 600;
    }
    .core_body {
      margin-top: 40px;
      display: flex;
      justify-content: space-evenly;
      .core_item {
        width: 250px;
        height: 120px;
        padding: 20px;
        border-radius: 9px;
        border: 1px solid #eaeff3;

        .icon {
          display: flex;
          font-size: 18px;
          color: #222;
          > i {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            background: url("../assets/basic/icon_col01.png");
          }
        }
        .cont {
          margin-top: 20px;
          font-size: 14px;
          color: #939393;
        }
      }
    }
  }
}
</style>