<!--
 * @Auhtor: 文锋
 * @Date: 2021-08-07 07:56:53
 * @LastEditors: Do not edit
 * @LastEditTime: 2021-08-07 15:50:39
 * @FilePath: \anqin\src\components\common\ConterHeader.vue
-->
<template>
  <div class="conter_box">
      <p class="title">{{title}}</p>
      <p class="content">{{content}}</p>
      <div class="btn_box">
        <div class="btn" v-show="type == 2" @click="$router.push('/product')">
            <img
                class="btn_icon"
                referrerpolicy="no-referrer"
                :src="require('@/assets/basic/nav_school.png')"
            />
            <span class="txt">院校入驻</span>
        </div>
        <div class="btn" v-show="type == 3" @click="$router.push('/product')">
            <img
                class="btn_icon"
                referrerpolicy="no-referrer"
                :src="require('@/assets/basic/nav_company.png')"
            />
            <span class="txt">企业入驻</span>
        </div>
         <div class="btn" v-show="type == 1" @click="$router.push('/product')">
            <img
                class="btn_icon"
                referrerpolicy="no-referrer"
                :src="require('@/assets/basic/nav_download_active.png')"
            />
            <span class="txt">立即下载</span>
        </div>
        <div class="btn other" v-show="type == 2 || type == 3" @click="$router.push('/product')">
            <img
                class="btn_icon"
                referrerpolicy="no-referrer"
                :src="require('@/assets/basic/nav_download.png')"
            />
            <span class="txt">立即下载</span>
        </div>
      </div>
  </div>
</template>
<script>
export default {
    name:'conterHeader',
    props:{
        title:{
            type: String,
            default:'校园生活'
        },
        content:{
            type:String,
            default:'优质内容、活跃校园社区、便利校园应用不止陪伴你的学习，更见证你的成长'
        },
        type: {
          type:Number,
          default:1
        }
    }
};
</script>

<style lang="scss" scoped>
.conter_box {
    box-sizing: border-box;
    padding: 60px 40px;
  width: 1200px;
  height: 310px;
  background: linear-gradient(176deg, #ac0202 0%, #c40202 56%, #ac0202 100%);
  border-radius: 10px;
  .title {
    margin: 0;
    font-size: 40px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FFFFFF;
  }
  .content {
      font-size: 16px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
  }
  .btn_box {
      margin-top: 50px;
      display: flex;
      .btn {
        display: flex;
        align-items: center;
        margin-right: 40px;
        padding: 21px 30px;
        border-radius: 6px;
        justify-content: space-between;
        background: #fff;
        transition: 0.5s all ease;
        box-shadow: inset 0px -1px 0px 0px rgba(255, 255, 255, 0.3);
        .btn_icon {
        width: 16px;
        height: 16px;
        margin-right: 10px;
        
        }
        .txt {
        width: 64px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(189, 39, 27, 1);
        font-size: 16px;
        white-space: nowrap;
        line-height: 16px;
        text-align: center;
        }
        &:hover {
          transform: translateY(-4px);
        }
    }
    .other {
      background: rgba(255,255,255,0.2);
      .txt {
        color: #fff;
      }
    }
  }
}
</style>