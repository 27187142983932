<template>
    <div class="wrapper">
        <div class="page_tips">首页>最新资讯</div>
        <div class="art_cont">
            <div class="title">
                <div class="btn" @click="$router.push('article')">返回</div>
                <div class="txt">
                    <p>关于做好2018-2019学年第一学期期末考试工作的通知</p>
                    <div><span>发布者：教务处</span><span>发布时间：2021-04-25</span></div>
                </div>
            </div>
            <div class="art_content" v-html="artCont"></div>
        </div>
    </div>
</template>
<script>
export default {
    name:'articleDetail',
    data() {
        return {
            artCont: `<span class="infoBox1">
          各二级学院、各有关部门：
          <br />
          <br />
          2018-2019学年第一学期期末统一考试将于2019年1月12日开始，为确保考试工作顺利进行，现将有关事宜通知如下：
          <br />
          <br />
          一、考试时间安排
          <br />
          <br />
          （一）期末考试日程为2019年1月12--18日。
          <br />
          <br />
          （二）每天安排两场考试，每场考试120分钟。
          <br />
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;上午&nbsp;09:00-11:00，下午14:30-16:30。
          <br />
          <br />
          二、考试安排事项
          <br />
          <br />
          （一）监考教师安排
          <br />
          <br />
          1.除有巡考任务的教师外，其他所有纳入教研室管理、本学期有授课任务的专兼职教师，一律参加期末监考。确有特殊原因不能参加监考的教师，12月25日前向所在二级学院办理请假手续（附件1）(各二级学院请假教师比例不高于10%)。12月26日下午下班前，各二级学院将请假获批不参加监考的教师汇总表（附件2）打印，由院长签字并加盖公章后交教务处考试管理科备案。
          <br />
          <br />
          2.12月24日前各二级学院将监考教师名单导入系统，教务处12月25日安排期末监考，2019年1月7日公布期末考试监考安排表。
          <br />
          <br />
          3.行政兼职教师，由其所在教学系的二级学院负责通知查询监考任务安排情况。
          <br />
          <br />
          4.各二级学院应组织监考教师及相关人员认真学习《淮南师范学院考试工作管理规程》、《淮南师范学院教学事故认定及处理办法》。
          <br />
          <br />
          5.监考任务落实后，不得随意调换。如确有特殊情况，监考教师应提前到所属二级学院教学办公室填写《淮南师范学院考试监考调换申请表》（附件3），考试时替换监考任务教师必须持原件备查。
        </span>`
        }
    }
}
</script>
<style lang="scss" scoped>
.wrapper {
    width: 1200px;
    margin: 20px auto 60px;
    .page_tips {
        color: #AFAFAF;
        font-size: 14px;
        margin-bottom: 20px;
    }
    .art_cont {
        .title {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #EAEFF3;
            .btn {
                border: 1px solid #EAEFF3;
                width: 90px;
                height: 44px;
                line-height: 44px;
                font-size: 16px;
                text-align: center;
                border-radius: 6px;
            }
            .txt {
                flex: 1;
                p {
                    text-align: center;
                    font-size: 24px;
                    font-family: PingFangSC-Semibold, PingFang SC;
                    font-weight: 600;
                    color: #222222;
                    line-height: 24px;
                }
                >div {
                    margin: 20px auto 40px;
                    font-size: 14px;                   
                    color: #AFAFAF;
                    text-align: center;
                }
            }
        }
        .art_content {
            margin: 40px auto 120px;
            padding: 0 20px;
        }
    }
}
</style>