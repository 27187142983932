<template>
    <div class="footer">
        <div class="section9 flex">
          <div class="bd14 flex-col">
            <img
              class="pic8"
              referrerpolicy="no-referrer"
              :src="require('@/assets/basic/footer_logo.png')"
            />
            <div class="bd15 flex-row">
              <span class="word16">Copyright</span>
              <img
                class="label5"
                referrerpolicy="no-referrer"
                :src="require('@/assets/basic/footer_c.png')"
              />
              <span class="txt9">安徽创滕互联科技有限公司版权所有</span>
            </div>
            <span class="word17">Tel：0551-45678912</span>
            <div class="bd16 flex-row">
              <img
                class="label6"
                referrerpolicy="no-referrer"
                :src="require('@/assets/basic/footer_wechat.png')"
              />
              <img
                class="icon3"
                referrerpolicy="no-referrer"
                :src="require('@/assets/basic/footer_weibo.png')"
              />
              <img
                class="icon4"
                referrerpolicy="no-referrer"
                :src="require('@/assets/basic/footer_qq.png')"
              />
            </div>
            <div class="bd17 flex-col">
              <img
                class="img3"
                referrerpolicy="no-referrer"
                :src="require('@/assets/basic/footer_wem.png')"
              />
              <div class="section11 flex-row">
                <span class="word25">皖ICP备&nbsp;14046450号-3</span>
                <span class="txt17">皖网安备31011002001832</span>
              </div>
            </div>
          </div>
          <div class="bd18 flex-col">
            <div class="section10 flex-col">
              <span class="info5">安青前程</span>
              <router-link to="/about" class="nounderline"><span class="info6">关于我们</span></router-link>
              <!-- <span class="word18">合作伙伴</span> -->
              <router-link to="/about" class="nounderline"><span class="txt10">联系我们</span></router-link>
              <!-- <span class="info7">加入我们</span> -->
              <span class="word19">意见反馈</span>
            </div>
          </div>
          <div class="bd19 flex-col">
            <div class="outer7 flex-col">
              <span class="word20">快速入口</span>
              <router-link to="/about" class="nounderline"><span class="word21">职教中心</span></router-link>
              <router-link to="/secondClass?index=0" class="nounderline"><span class="txt11">第二课堂</span></router-link>
              <span class="word22">大国工匠</span>
              <span class="txt12">创新创业/技能大赛</span>
              <router-link to="/schoolLife" class="nounderline"><span class="word23">校园服务</span></router-link>
              <router-link to="/teach" class="nounderline"><span class="txt13">智能校园</span></router-link>
              <router-link to="/company" class="nounderline"><span class="txt14">企业服务</span></router-link>
            </div>
          </div>
          <div class="bd20 flex-col">
            <div class="group9 flex-col">
              <span class="info8">友情链接</span>
              <span class="info9">CEO运营实战平台</span>
              <span class="info10">菁星商城（店铺）</span>
              <span class="txt15">菁星赛事后台</span>
              <span class="info11">菁星校园报修服务平台</span>
              <span class="txt16">第二课堂后台</span>
              <span class="info12">网络大厅后台</span>
              <span class="word24">通信大厅后台</span>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.footer {
      height: 420px;
      background-color: rgba(10, 23, 51, 1);
      margin: 0px auto ;
      padding-top: 60px;
      width: 100%;
      .flex {display: flex;}
      .section9 {
        z-index: auto;
        width: 1200px;
        height: 324px;
        margin: 0px auto;
        .bd14 {
          z-index: auto;
          width: 312px;
          height: 314px;
          .pic8 {
            z-index: 375;
            width: 170px;
            height: 60px;
            align-self: flex-start;
          }
          .bd15 {
            z-index: auto;
            width: 312px;
            height: 20px;
            margin-top: 20px;
            justify-content: space-between;
            .word16 {
              z-index: 372;
              width: 64px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(109, 115, 133, 1);
              font-size: 14px;
              white-space: nowrap;
              line-height: 20px;
              text-align: left;
            }
            .label5 {
              z-index: 344;
              width: 14px;
              height: 14px;
              margin-top: 3px;
            }
            .txt9 {
              z-index: 374;
              width: 224px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(109, 115, 133, 1);
              font-size: 14px;
              white-space: nowrap;
              line-height: 20px;
              text-align: left;
            }
          }
          .word17 {
            z-index: 373;
            width: 137px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(109, 115, 133, 1);
            font-size: 14px;
            white-space: nowrap;
            line-height: 20px;
            text-align: left;
            align-self: flex-start;
            margin-top: 20px;
          }
          .bd16 {
            z-index: auto;
            width: 162px;
            height: 34px;
            margin-top: 20px;
            justify-content: space-between;
            .label6 {
              z-index: 320;
              width: 34px;
              height: 34px;
            }
            .icon3 {
              z-index: 331;
              width: 34px;
              height: 34px;
            }
            .icon4 {
              z-index: 341;
              width: 34px;
              height: 34px;
            }
          }
          .bd17 {
            z-index: 400;
            height: 100px;
            background-color: rgba(255, 255, 255, 1);
            align-self: flex-start;
            margin-top: 20px;
            width: 100px;
            .img3 {
              z-index: 401;
              width: 100px;
              height: 100px;
            }
          }
        }
        .bd18 {
          z-index: 319;
          height: 324px;
          border-width: 1px;
          border: 1px solid rgba(151, 151, 151, 0);
          background-color: rgba(216, 216, 216, 0);
          margin-left: 248px;
          width: 180px;
          justify-content: flex-start;
          align-items: flex-start;
          .section10 {
            z-index: auto;
            width: 56px;
            height: 220px;
            .info5 {
              z-index: 348;
              width: 56px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 14px;
              white-space: nowrap;
              line-height: 20px;
              text-align: left;
            }
            .info6 {
              z-index: 352;
              width: 56px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(109, 115, 133, 1);
              font-size: 14px;
              white-space: nowrap;
              line-height: 20px;
              text-align: left;
              margin-top: 20px;
            }
            .word18 {
              z-index: 355;
              width: 56px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(109, 115, 133, 1);
              font-size: 14px;
              white-space: nowrap;
              line-height: 20px;
              text-align: left;
              margin-top: 20px;
            }
            .txt10 {
              z-index: 356;
              width: 56px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(109, 115, 133, 1);
              font-size: 14px;
              white-space: nowrap;
              line-height: 20px;
              text-align: left;
              margin-top: 20px;
            }
            .info7 {
              z-index: 354;
              width: 56px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(109, 115, 133, 1);
              font-size: 14px;
              white-space: nowrap;
              line-height: 20px;
              text-align: left;
              margin-top: 20px;
            }
            .word19 {
              z-index: 353;
              width: 56px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(109, 115, 133, 1);
              font-size: 14px;
              white-space: nowrap;
              line-height: 20px;
              text-align: left;
              margin-top: 20px;
            }
          }
        }
        .bd19 {
          z-index: 318;
          height: 324px;
          border-width: 1px;
          border: 1px solid rgba(151, 151, 151, 0);
          background-color: rgba(216, 216, 216, 0);
          margin-left: 50px;
          width: 180px;
          justify-content: flex-start;
          align-items: flex-start;
          .outer7 {
            z-index: auto;
            width: 119px;
            height: 300px;
            .word20 {
              z-index: 351;
              width: 56px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 14px;
              white-space: nowrap;
              line-height: 20px;
              text-align: left;
              align-self: flex-start;
            }
            .word21 {
              z-index: 357;
              width: 56px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(109, 115, 133, 1);
              font-size: 14px;
              white-space: nowrap;
              line-height: 20px;
              text-align: left;
              align-self: flex-start;
              margin-top: 20px;
            }
            .txt11 {
              z-index: 359;
              width: 56px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(109, 115, 133, 1);
              font-size: 14px;
              white-space: nowrap;
              line-height: 20px;
              text-align: left;
              align-self: flex-start;
              margin-top: 20px;
            }
            .word22 {
              z-index: 361;
              width: 56px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(109, 115, 133, 1);
              font-size: 14px;
              white-space: nowrap;
              line-height: 20px;
              text-align: left;
              align-self: flex-start;
              margin-top: 20px;
            }
            .txt12 {
              z-index: 363;
              width: 119px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(109, 115, 133, 1);
              font-size: 14px;
              white-space: nowrap;
              line-height: 20px;
              text-align: left;
              margin-top: 20px;
            }
            .word23 {
              z-index: 365;
              width: 56px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(109, 115, 133, 1);
              font-size: 14px;
              white-space: nowrap;
              line-height: 20px;
              text-align: left;
              align-self: flex-start;
              margin-top: 20px;
            }
            .txt13 {
              z-index: 367;
              width: 56px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(109, 115, 133, 1);
              font-size: 14px;
              white-space: nowrap;
              line-height: 20px;
              text-align: left;
              align-self: flex-start;
              margin-top: 20px;
            }
            .txt14 {
              z-index: 369;
              width: 56px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(109, 115, 133, 1);
              font-size: 14px;
              white-space: nowrap;
              line-height: 20px;
              text-align: left;
              align-self: flex-start;
              margin-top: 20px;
            }
          }
        }
        .bd20 {
          z-index: 317;
          height: 324px;
          border-width: 1px;
          border: 1px solid rgba(151, 151, 151, 0);
          background-color: rgba(216, 216, 216, 0);
          margin-left: 50px;
          width: 180px;
          justify-content: flex-start;
          align-items: flex-start;
          .group9 {
            z-index: auto;
            width: 140px;
            height: 300px;
            .info8 {
              z-index: 349;
              width: 56px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(255, 255, 255, 1);
              font-size: 14px;
              white-space: nowrap;
              line-height: 20px;
              text-align: left;
              align-self: flex-start;
            }
            .info9 {
              z-index: 358;
              width: 114px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(109, 115, 133, 1);
              font-size: 14px;
              white-space: nowrap;
              line-height: 20px;
              text-align: left;
              align-self: flex-start;
              margin-top: 20px;
            }
            .info10 {
              z-index: 360;
              width: 112px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(109, 115, 133, 1);
              font-size: 14px;
              white-space: nowrap;
              line-height: 20px;
              text-align: left;
              align-self: flex-start;
              margin-top: 20px;
            }
            .txt15 {
              z-index: 362;
              width: 84px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(109, 115, 133, 1);
              font-size: 14px;
              white-space: nowrap;
              line-height: 20px;
              text-align: left;
              align-self: flex-start;
              margin-top: 20px;
            }
            .info11 {
              z-index: 364;
              width: 140px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(109, 115, 133, 1);
              font-size: 14px;
              white-space: nowrap;
              line-height: 20px;
              text-align: left;
              margin-top: 20px;
            }
            .txt16 {
              z-index: 366;
              width: 84px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(109, 115, 133, 1);
              font-size: 14px;
              white-space: nowrap;
              line-height: 20px;
              text-align: left;
              align-self: flex-start;
              margin-top: 20px;
            }
            .info12 {
              z-index: 368;
              width: 84px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(109, 115, 133, 1);
              font-size: 14px;
              white-space: nowrap;
              line-height: 20px;
              text-align: left;
              align-self: flex-start;
              margin-top: 20px;
            }
            .word24 {
              z-index: 370;
              width: 84px;
              display: block;
              overflow-wrap: break-word;
              color: rgba(109, 115, 133, 1);
              font-size: 14px;
              white-space: nowrap;
              line-height: 20px;
              text-align: left;
              align-self: flex-start;
              margin-top: 20px;
            }
          }
        }
      }
      .section11 {
        z-index: auto;
        width: 354px;
        height: 20px;
        justify-content: space-between;
        margin: 10px 0 0 0px;
        .word25 {
          z-index: 350;
          width: 151px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(109, 115, 133, 1);
          font-size: 14px;
          white-space: nowrap;
          line-height: 20px;
          text-align: left;
        }
        .txt17 {
          z-index: 371;
          width: 163px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(109, 115, 133, 1);
          font-size: 14px;
          white-space: nowrap;
          line-height: 20px;
          text-align: left;
        }
      }
    }
.nounderline {
  text-decoration: none;
}
</style>